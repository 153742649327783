import { createSlice } from '@reduxjs/toolkit';

import {
    INITIAL_VALUES,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_CONFIG_CSV_IMPORTER,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    configCsvImporter: { file: null, isOpen: false },
    initialValues: {
        compensation_scheme: '',
        degradation_percentage: 0,
        energy_export_price: 0,
        file: null,
        generation: 0,
        guaranteed_gen_active: false,
        guaranteed_gen: 0,
        kwhValue: 0,
        netted_exported_generation_price: 0,
        source: 0,
        sunHoursMode: 'sunHoursValue',
        sunHoursValue: 0,
        urlFile: '',
        zero_export: false,
    },
    save: {
        data: [],
        errors: [],
        isSaving: false,
    },
};

const advancedGenerationSettingsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_CONFIG_CSV_IMPORTER]: (state, action) => {
            state.configCsvImporter = action.payload;
        },
    },
});

export const advancedGenerationSettingsAction =
    advancedGenerationSettingsSlice.actions;

export default advancedGenerationSettingsSlice.reducer;
