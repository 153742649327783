import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const ConceptValue = ({
    currencyIso,
    currencyLocale,
    isFetching,
    isHidden = false,
    numericalSign,
    value,
}) => {
    if (isHidden) return null;

    if (isFetching)
        return (
            <Box display="flex" justifyContent="flex-end">
                <Skeleton
                    variant="text"
                    sx={{
                        fontSize: '1rem',
                        width: 200,
                    }}
                />
            </Box>
        );
    return (
        <Box display="flex" justifyContent="flex-end">
            <Typography
                sx={{
                    cursor: 'pointer',
                    fontWeight: { xs: 'normal', lg: 'bold' },
                }}
                variant="body2"
            >
                {numericalSign && <span>{numericalSign}</span>}{' '}
                {numberFormat(value, {
                    currency: currencyIso,
                    decimals: 2,
                    locale: currencyLocale,
                    style: 'currency',
                })}
            </Typography>
        </Box>
    );
};

ConceptValue.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    isHidden: PropTypes.bool,
    numericalSign: PropTypes.string,
    value: PropTypes.number,
};

export default ConceptValue;
