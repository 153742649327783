import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const DiscountRateField = ({ canModify, control, isLocked }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Financial data')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} lg={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={isLocked || !canModify}
                        fullWidth
                        key="discount_rate"
                        label={t('Discount rates')}
                        min={0}
                        max={100}
                        name="discount_rate"
                    />
                </Grid>
            </Grid>
        </>
    );
};

DiscountRateField.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default DiscountRateField;
