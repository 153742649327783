import React, { useEffect } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import discountValidate from '../discountValidate';
import { percentageToValue, valueToPercentage } from '../helpers';
import * as selectors from '../selectors';

import DiscountTypeGroup from './DiscountTypeGroup';

const DiscountForm = ({
    currencySymbol,
    initialValues,
    isSaving,
    onlySubtotal = false,
    proposalId,
    save,
    setEditable,
    subtotal,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { onlySubtotal, subtotal },
        defaultValues: initialValues,
        resolver: yupResolver(discountValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) =>
        save(proposalId, values, () => setEditable(false));

    const handleChangeDiscountPercentage = ({ value }) => {
        const newValue = percentageToValue({ percentage: value, subtotal });
        setValue('discount_amount', toNumber(newValue).toFixed(2));
    };
    const handleChangeDiscountAmount = ({ value }) => {
        const newValue = valueToPercentage({ subtotal, value });
        setValue('discount_percentage', toNumber(newValue).toFixed(2));
    };
    const handleChangeSubtotal2 = ({ value }) => {
        const discountAmount = subtotal - value;
        const newValue = valueToPercentage({ subtotal, value: discountAmount });
        setValue('discount_amount', toNumber(discountAmount).toFixed(2));
        setValue('discount_percentage', toNumber(newValue).toFixed(2));
    };

    const isPercentage = watch('type_discount') === 0;

    const renderFields = () => {
        if (onlySubtotal)
            return (
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={isSaving}
                    label={`${t('Subtotal')} (2)`}
                    min="0"
                    name="subtotalWithDiscount"
                    onBlur={handleChangeSubtotal2}
                    prepend={currencySymbol}
                    variant="standard"
                />
            );
        return (
            <Box alignItems="center" display="flex" gap={2}>
                {isPercentage && (
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isSaving}
                        label={t('Discount')}
                        min="0"
                        name="discount_percentage"
                        onBlur={handleChangeDiscountPercentage}
                        variant="standard"
                    />
                )}
                {!isPercentage && (
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        control={control}
                        disabled={isSaving}
                        label={t('Discount')}
                        min="0"
                        name="discount_amount"
                        onBlur={handleChangeDiscountAmount}
                        prepend={currencySymbol}
                        variant="standard"
                    />
                )}
                <DiscountTypeGroup
                    handleOnChange={(value) => setValue('type_discount', value)}
                    selected={watch('type_discount')}
                />
            </Box>
        );
    };

    return (
        <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="flex-end"
        >
            {renderFields()}
            <Tooltip title={t('Save changes')}>
                <IconButton onClick={handleSubmit(handleOnSave)}>
                    <SaveIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('Cancel')}>
                <IconButton onClick={() => setEditable(false)}>
                    <CancelIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

DiscountForm.propTypes = {
    currencySymbol: PropTypes.string,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    onlySubtotal: PropTypes.bool,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    setEditable: PropTypes.func,
    subtotal: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialDiscountValues,
    isSaving: selectors.getIsSavingDiscount,
});

const mapDispatchToProps = (dispatch) => ({
    save: (proposalId, values, callback) =>
        dispatch(actions.saveDiscount(proposalId, values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountForm);
