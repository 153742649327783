import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

const PowerFactorFields = ({ control, isDisabled }) => {
    const { t } = useTranslation();
    const apply_desired_power_factor = useWatch({
        control,
        name: 'apply_desired_power_factor',
    });

    return (
        <Grid container>
            <Grid item xs={3} md={1}>
                <ReactHookFormCheck
                    control={control}
                    disabled={isDisabled}
                    label={''}
                    name="apply_desired_power_factor"
                    sxFormControl={{
                        mb: '0px !important',
                        '& .MuiFormControlLabel-root': { margin: 0 },
                    }}
                />
            </Grid>
            <Grid item xs={15} md={8}>
                <ReactHookFormIntlNumberInput
                    control={control}
                    disabled={isDisabled || !apply_desired_power_factor}
                    fullWidth
                    label={t('Target power factor')}
                    name="desired_power_factor"
                />
            </Grid>
        </Grid>
    );
};

PowerFactorFields.propTypes = {
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default PowerFactorFields;
