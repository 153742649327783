import React from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Link } from 'sunwise-ui';

import { InfoToolTip, TitleWithDetail } from 'common/components';
import { ReactHookFormCheck } from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

const ModifyCalculationField = ({ control, isLocked, nextRateData }) => {
    const { t } = useTranslation();

    if (
        !isNull(nextRateData) &&
        nextRateData?.certified &&
        HIGH_TENSION_RATES.includes(nextRateData.name)
    )
        return (
            <Box>
                <Box sx={{ mb: 2 }}>
                    <TitleWithDetail variant="body2">
                        {t('Supplier')}
                    </TitleWithDetail>
                </Box>
                <Box alignItems="center" display="flex" gap={1} mb={2}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked}
                        label={t('Modify calculation to pre-2018 resolution')}
                        name="deprecated"
                        sxFormControl={{ mb: '0px !important' }}
                    />

                    <InfoToolTip variant="no-wrapper">
                        {t(
                            'Affects the calculation formula for "Capacidad" and "Distribución" concepts. Learn more at',
                        )}{' '}
                        <Link
                            href={`${
                                import.meta.env.VITE_ACADEMY_URL
                            }es/articles/9332918-calculos-de-capacidad-y-distribucion-resolucion-pre-2018`}
                            target="_blank"
                        >
                            Sunwise Academy
                        </Link>
                    </InfoToolTip>
                </Box>
            </Box>
        );
    return null;
};

ModifyCalculationField.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    nextRateData: PropTypes.object,
};

export default ModifyCalculationField;
