import { getPlan } from 'common/api/v1/plans';

import {
    FETCH_PLAN,
    FETCH_PLAN_FAILURE,
    FETCH_PLAN_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

export default (id, setValue) => (dispatch) => {
    dispatch(proposalGeneratorConfigModalActions[FETCH_PLAN]());
    getPlan(id)
        .then((response) => {
            const item = response.data;
            dispatch(
                proposalGeneratorConfigModalActions[FETCH_PLAN_SUCCESS](item),
            );
            setValue('bag_years', item.simulation_years);
            setValue('deprecated', item.pre_2018_enabled);
            setValue('generation_delay', item.start_in_months);
            setValue('isr', item.tax_incentive_enabled);
            setValue('isr_percentage', item.tax_incentive_percentage);
            setValue('opex', item.opex);
            setValue('opex_active', item.opex_active);
            setValue('opex_percentage_increase', item.opex_percentage);
            setValue('opex_periodicity', item.opex_periodicity);
            setValue('ppa_active', item.ppa_active);
            setValue('ppa_price', item.ppa_price);
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorConfigModalActions[FETCH_PLAN_FAILURE](error),
            );
        });
};
