import i18next from 'i18next';
import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        getMaxValueText,
        getMinValueText,
        INVALID_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        branch_office: string().required(REQUIRED_TEXT).nullable(),
        installation_type: object()
            .test(
                'at-least-one-true',
                i18next.t('At least one installation type must be selected'),
                (value) => Object.values(value).some((val) => val === true),
            )
            .required(REQUIRED_TEXT),
        saving_percentage: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        bag_years: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(50, getMaxValueText(50))
            .nullable(),
        generation_delay: string().required(REQUIRED_TEXT),
        costing_type: string().required(REQUIRED_TEXT).nullable(),
        cost_ranges_kwh: array().when('costing_type', {
            is: 'by_watt',
            then: () =>
                array()
                    .of(
                        object().shape({
                            min: number().typeError(REQUIRED_TEXT).nullable(),
                            max: number()
                                .typeError(REQUIRED_TEXT)
                                .test('min', INVALID_TEXT, function (value) {
                                    const { min } = this.parent;
                                    if (!min && min !== 0) return true;
                                    return value >= min;
                                })
                                .nullable(),
                            cost: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            margin: number()
                                .max(100, getMaxValueText(100))
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            price: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                        }),
                    )
                    .min(1, 'Cost ranges watt must have at least one element')
                    .required('Cost ranges watt is required'),
        }),
        cost_ranges_watt: array().when('costing_type', {
            is: 'by_watt',
            then: () =>
                array()
                    .of(
                        object().shape({
                            min: number().typeError(REQUIRED_TEXT).nullable(),
                            max: number()
                                .typeError(REQUIRED_TEXT)
                                .test('min', INVALID_TEXT, function (value) {
                                    const { min } = this.parent;
                                    if (!min && min !== 0) return true;
                                    return value >= min;
                                })
                                .nullable(),
                            cost: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            margin: number()
                                .max(100, getMaxValueText(100))
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            price: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                        }),
                    )
                    .min(1, 'Cost ranges watt must have at least one element')
                    .required('Cost ranges watt is required'),
        }),
        panel: string().required(REQUIRED_TEXT),
        inverter_brand: string().required(REQUIRED_TEXT),
        desired_power_factor: number().when('apply_desired_power_factor', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        guaranteed_gen: number().when('guaranteed_gen_active', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        ppa_price: number().when('ppa_active', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        ppa_percentage_increase: number().when('ppa_active', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_type: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(1, getMaxValueText(1))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        isr_percentage: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_annual: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 1,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(50, getMaxValueText(50))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_monthly: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(600, getMaxValueText(600))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        opex_costing_type: string().when('opex_active', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        opex: number().when(['opex_active', 'opex_costing_type'], {
            is: (opexActive, opexCostingType) =>
                opexActive === true && opexCostingType === 'by_fixed_amount',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        opex_percentage_increase: number().when(
            ['opex_active', 'opex_costing_type'],
            {
                is: (opexActive, opexCostingType) =>
                    opexActive === true &&
                    opexCostingType === 'by_fixed_amount',
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .nullable(),
                otherwise: () => string().nullable(),
            },
        ),
        opex_cost_ranges_kw: array().when('opex_costing_type', {
            is: 'by_kw_and_kwh',
            then: () =>
                array()
                    .of(
                        object().shape({
                            amount: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            annual_percentage_increase: number()
                                .max(100, getMaxValueText(100))
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            min: number().typeError(REQUIRED_TEXT).nullable(),
                            max: number()
                                .typeError(REQUIRED_TEXT)
                                .test('min', INVALID_TEXT, function (value) {
                                    const { min } = this.parent;
                                    if (!min && min !== 0) return true;
                                    return value >= min;
                                })
                                .nullable(),
                        }),
                    )
                    .min(1, 'Cost ranges watt must have at least one element')
                    .required('Cost ranges watt is required'),
        }),
        opex_cost_ranges_kwh: array().when('opex_costing_type', {
            is: 'by_kw_and_kwh',
            then: () =>
                array()
                    .of(
                        object().shape({
                            amount: number()
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            annual_percentage_increase: number()
                                .max(100, getMaxValueText(100))
                                .typeError(REQUIRED_TEXT)
                                .required(REQUIRED_TEXT),
                            min: number().typeError(REQUIRED_TEXT).nullable(),
                            max: number()
                                .typeError(REQUIRED_TEXT)
                                .test('min', INVALID_TEXT, function (value) {
                                    const { min } = this.parent;
                                    if (!min && min !== 0) return true;
                                    return value >= min;
                                })
                                .nullable(),
                        }),
                    )
                    .min(1, 'Cost ranges watt must have at least one element')
                    .required('Cost ranges watt is required'),
        }),
    });
};
