import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './actions';
import { Card } from './components';
import * as selectors from './selectors';

const Container = ({
    anualSolarGeneration,
    canModifyRoiSolarDegradation,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    complementsOfferData,
    initialize,
    irradiationValues,
    isLoading,
    isLocked,
    isSaving,
    offerConfigGeneration,
    proposalId,
    resetForm,
    save,
    systemSize,
}) => {
    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        if (!isEmpty(offerConfigGeneration) && !isEmpty(irradiationValues)) {
            const generation = offerConfigGeneration.generation || 0;
            const pvSystemDataSource =
                offerConfigGeneration.pv_system_data_source;

            initialize({
                csvSystemSize: offerConfigGeneration.csv_system_size,
                generation,
                kwhValue: irradiationValues.kWhkWValue,
                pvSystemDataSource,
                source: offerConfigGeneration.source,
                sunHoursValue: irradiationValues.sunHoursValue,
                urlFile: offerConfigGeneration.file,
                compensation_scheme: complementsOfferData?.compensation_scheme,
                energy_export_price: complementsOfferData?.energy_export_price,
                degradation_percentage:
                    complementsOfferData?.degradation_percentage,
                guaranteed_gen_active:
                    complementsOfferData?.guaranteed_gen_active,
                guaranteed_gen: complementsOfferData?.guaranteed_gen,
                netted_exported_generation_price:
                    complementsOfferData?.netted_exported_generation_price,
                zero_export: complementsOfferData?.zero_export,
            });
        }
    }, [
        complementsOfferData,
        irradiationValues,
        irradiationValues,
        offerConfigGeneration,
    ]);

    const onSubmit = (values) => save(proposalId, values);

    return (
        <Card
            anualSolarGeneration={anualSolarGeneration}
            averageIrradiation={
                offerConfigGeneration?.averages?.average_kwhkw || 0
            }
            canModifyRoiSolarDegradation={canModifyRoiSolarDegradation}
            canModifySolarGeneration={canModifySolarGeneration}
            canModifySolarGenerationFile={canModifySolarGenerationFile}
            hasDesignerGeneration={
                offerConfigGeneration?.has_designer_generation
            }
            isLocked={isLoading || isLocked || isSaving}
            onSubmit={onSubmit}
            proposalId={proposalId}
            sunHours={offerConfigGeneration?.averages?.average_hr || 0}
            systemSize={systemSize}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values) => dispatch(actions.save(proposalId, values)),
});

Container.propTypes = {
    anualSolarGeneration: PropTypes.number,
    canModifyRoiSolarDegradation: PropTypes.bool,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    complementsOfferData: PropTypes.object,
    initialize: PropTypes.func,
    irradiationValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    systemSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
