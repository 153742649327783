import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import {
    getCountryCurrencyIso,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import ModifyCalculationField from '../../proposalGeneratorConfigurationModal/components/ModifyCalculationField';
import PPAFields from '../../proposalGeneratorConfigurationModal/components/PPAFields';
import RoiFields from '../../proposalGeneratorConfigurationModal/components/RoiFields';

import GuaranteedGenerationFields from './GuaranteedGenerationFields';
import OpexFields from './OpexFields';
import PowerFactorFields from './PowerFactorFields';

const TabSystem = ({
    clearErrors,
    control,
    getValues,
    isDisabled,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Supplier')}
                </TitleWithDetail>
            </Box>

            <ModifyCalculationField control={control} isLocked={isDisabled} />

            <PowerFactorFields control={control} isLocked={isDisabled} />

            <GuaranteedGenerationFields
                control={control}
                isDisabled={isDisabled}
            />

            <PPAFields
                control={control}
                currencyIso={getCountryCurrencyIso()}
                currencySymbol={getCountryCurrencySymbol()}
                isLocked={isDisabled}
            />

            <RoiFields
                canModifyRoiTaxIncentive={true}
                clearErrors={clearErrors}
                control={control}
                isLocked={isDisabled}
                setValue={setValue}
            />

            <OpexFields
                control={control}
                currencyIso={getCountryCurrencyIso()}
                currencySymbol={getCountryCurrencySymbol()}
                getValues={getValues}
                isDisabled={isDisabled}
                setValue={setValue}
            />
        </>
    );
};

TabSystem.propTypes = {
    clearErrors: PropTypes.func,
    control: PropTypes.object,
    getValues: PropTypes.func,
    isDisabled: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TabSystem;
