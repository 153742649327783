import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import { isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { getHasPermission } from 'common/utils/helpers/permissionsV2';
import { getIsGuest } from 'common/utils/helpers/session';

import fondoLogin1 from 'resources/fondoLogin1.png';
import logotipo from 'resources/logotipoSunwiseAzul.svg';

import { loginUser } from './actions';
import LoginForm from './components/LoginForm';
import * as selectors from './selectors';

const StyledGrid = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #fff url(${fondoLogin1});
`;

const StyledCol = styled(Grid)`
    background: #f6f8fa;
`;

const Container = ({
    errors,
    initialValues,
    isLoadingSession,
    loginUser,
    redirect,
}) => {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!getIsGuest()) {
            const hasDashboardPermission = getHasPermission(
                PERMISSION_LIST.DASHBOARD_PERMISSION,
            );
            const hasContactsPermission = getHasPermission(
                PERMISSION_LIST.CONTACTS_PERMISSION,
            );
            if (hasDashboardPermission) redirect('/dashboard');
            else if (hasContactsPermission) redirect('/contacts');
            else redirect('/profile');
        }
    }, []);

    const handleSubmit = (values) =>
        loginUser(values, (accountLanguage) => {
            if (!isNil(accountLanguage) && i18n.language !== accountLanguage)
                i18n.changeLanguage(accountLanguage);
        });

    return (
        <Grid
            container
            spacing={0}
            sx={{ alignItems: 'center', height: '100%' }}
        >
            <StyledGrid
                item
                lg={8}
                sx={{ alignItems: 'center', display: 'flex', height: '100%' }}
                xs={18}
            >
                <OpacityAnimation>
                    <Box sx={{ margin: '0 auto', maxWidth: '670px', p: 2 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <img alt="Sunwise" src={logotipo} width="238" />
                        </Box>

                        <Box py={2} textAlign="center">
                            <Typography variant="h4">{t('Log in')}</Typography>
                        </Box>

                        <Divider sx={{ mb: 3 }} />

                        <LoginForm
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            isLoadingSession={isLoadingSession}
                            errors={errors}
                        />

                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                color="secondary"
                                component={RouterLink}
                                fullWidth
                                to="/reset-password"
                                variant="text"
                                sx={{ textTransform: 'none' }}
                            >
                                {t('Did you forget your password?')}
                            </Button>
                        </Box>

                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <Typography fontWeight="bold" variant="body2">
                                {t("Don't have an account?")}{' '}
                                <Link to="/create-account">{t('Sign up')}</Link>
                            </Typography>
                        </Box>
                    </Box>
                </OpacityAnimation>
            </StyledGrid>

            <StyledCol
                item
                md={10}
                sx={{
                    alignItems: 'center',
                    display: { lg: 'flex', xs: 'none' },
                    height: '100%',
                }}
            >
                <iframe
                    src="https://sunwise.webflow.io/loginportal"
                    style={{ border: 0, height: '100%', width: '100%' }}
                />
            </StyledCol>
        </Grid>
    );
};

Container.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    loginUser: PropTypes.func,
    redirect: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsLogin,
    initialValues: selectors.getInitialValues,
    isEmptyPosts: selectors.getPostsIsEmpty,
    isLoadingSession: selectors.getIsLoadingSession,
    postsData: selectors.getPostsData,
    validPassword: selectors.getValidPassword,
});

const mapDispatchToProps = (dispatch) => ({
    loginUser: (data, onSuccessCallback) =>
        dispatch(loginUser(data, onSuccessCallback)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    loginUser: PropTypes.func,
    redirect: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
