import bulkUpdateProducts from './bulkUpdateProducts';
import initializeUpdateProductsForm from './initializeUpdateProductsForm';
import openModal from './openModal';
import setBulkAction from './setBulkAction';

export default (action, proposalId, selectionModel, callback) => (dispatch) => {
    const values = {
        selectionModel,
        callback,
    };
    dispatch(setBulkAction(action));
    if (action === 'deleted_mode')
        dispatch(bulkUpdateProducts(proposalId, values, 'deleted_mode'));
    else {
        dispatch(initializeUpdateProductsForm(values));
        dispatch(openModal(true));
    }
};
