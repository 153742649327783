import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { getCompensationHasNettedExportedGeneration } from 'common/utils/helpers/rates';

const ExportedEnergyFields = ({
    control,
    currencySymbol,
    handleOnChange,
    handleSubmit,
    hasOfferProfiles,
    isLocked,
    setValue,
    showCheckbox = false,
}) => {
    const { t } = useTranslation();
    const [compensationScheme, zeroExport] = useWatch({
        control,
        name: ['compensation_scheme', 'zero_export'],
    });

    const hasNettedExportedGeneration =
        getCompensationHasNettedExportedGeneration(compensationScheme);

    return (
        <Box px={2}>
            <TitleWithDetail variant="body2">
                {t('Exported solar generation')}
            </TitleWithDetail>

            <Grid container>
                <Grid
                    item
                    xs={18}
                    md={9}
                    lg={hasNettedExportedGeneration ? 7 : 6}
                >
                    <Box alignItems="center" display="flex" gap={2}>
                        {(hasOfferProfiles || showCheckbox) && (
                            <ReactHookFormCheck
                                control={control}
                                disabled={isLocked}
                                label={t('Zero export')}
                                name="zero_export"
                                onChange={({ value }) =>
                                    handleOnChange({
                                        setValue,
                                        field: 'zero_export',
                                        value,
                                        handleSubmit,
                                    })
                                }
                                sxFormControl={{ flexShrink: 0 }}
                            />
                        )}
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isLocked || zeroExport}
                            fullWidth
                            label={
                                hasNettedExportedGeneration
                                    ? t('Excess exported generation price')
                                    : t('Price')
                            }
                            name="energy_export_price"
                            onChange={({ target: { value } }) =>
                                handleOnChange({
                                    setValue,
                                    field: 'energy_export_price',
                                    value,
                                    handleSubmit,
                                })
                            }
                            prepend={currencySymbol}
                        />
                    </Box>
                </Grid>
                {hasNettedExportedGeneration && (
                    <Grid item xs={18} md={9} lg={4}>
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isLocked || zeroExport}
                            fullWidth
                            label={t('Netted exported generation price')}
                            name="netted_exported_generation_price"
                            onChange={({ target: { value } }) =>
                                handleOnChange({
                                    setValue,
                                    field: 'netted_exported_generation_price',
                                    value,
                                    handleSubmit,
                                })
                            }
                            prepend={currencySymbol}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

ExportedEnergyFields.propTypes = {
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    handleOnChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    hasOfferProfiles: PropTypes.bool,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
    showCheckbox: PropTypes.bool,
};

export default ExportedEnergyFields;
