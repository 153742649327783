import React from 'react';

import PropTypes from 'prop-types';
import { Box, Divider, Typography } from 'sunwise-ui';

const ProductsContainer = ({ children, icon, title }) => {
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={2} mb={1}>
                <Box display="flex" alignItems="center" sx={{ width: '24px' }}>
                    {icon}
                </Box>
                <Typography fontWeight="bold" variant="body2">
                    {title}
                </Typography>
            </Box>
            {children}
            <Divider sx={{ mb: 2 }} />
        </Box>
    );
};

ProductsContainer.propTypes = {
    children: PropTypes.object,
    icon: PropTypes.object,
    title: PropTypes.string,
};

export default ProductsContainer;
