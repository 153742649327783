import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import BulkEditionMenu from './BulkEditionMenu';

const BulkOperationsCard = ({
    costingType,
    handleClickBulkItems,
    proposalId,
    selectionModel,
    setSelectionModel,
    totalItems,
}) => {
    const { t } = useTranslation();

    const callback = () => setSelectionModel({});
    const isProductCosting = costingType === 'by_product';

    if (isEmpty(selectionModel)) return null;

    return (
        <Grid
            justifyContent="flex-end"
            item
            xs={18}
            md={9}
            lg={4}
            sx={{
                '&.MuiGrid-item': {
                    pt: 0,
                },
            }}
        >
            <BulkEditionMenu
                buttonSize="small"
                callback={callback}
                items={[
                    {
                        label: t('Change margin'),
                        handleClick: () =>
                            handleClickBulkItems(
                                'margin',
                                proposalId,
                                selectionModel,
                                callback,
                            ),
                        visible: isProductCosting,
                    },
                    {
                        label: t('Change discount'),
                        handleClick: () =>
                            handleClickBulkItems(
                                'discount',
                                proposalId,
                                selectionModel,
                                callback,
                            ),
                        visible: isProductCosting,
                    },
                    {
                        label: t('Remove'),
                        handleClick: () =>
                            handleClickBulkItems(
                                'deleted_mode',
                                proposalId,
                                selectionModel,
                                callback,
                            ),
                        visible: true,
                    },
                ]}
                prefix={t('Product', {
                    count: selectionModel.length,
                }).toLocaleLowerCase()}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                totalItems={totalItems}
            />
        </Grid>
    );
};

BulkOperationsCard.propTypes = {
    costingType: PropTypes.string,
    handleClickBulkItems: PropTypes.func,
    proposalId: PropTypes.string,
    selectionModel: PropTypes.object,
    setSelectionModel: PropTypes.func,
    totalItems: PropTypes.number,
};

export default BulkOperationsCard;
