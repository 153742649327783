import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    margin-left: 8px;
    & .MuiToggleButton-primary {
        color: #545759;
        padding: 6px 12px;
        &.Mui-selected {
            background-color: #dcdbdc;
        }
    }
`;

const DiscountTypeGroup = ({
    currencySymbol = '$',
    disabled,
    handleOnChange,
    selected,
    visible = true,
}) => {
    if (!visible) return null;
    return (
        <StyledToggleButtonGroup
            color="primary"
            disabled={disabled}
            exclusive
            onChange={(e) => handleOnChange(parseInt(e.target.value))}
            value={selected}
            size="small"
        >
            <ToggleButton value={0}>%</ToggleButton>
            <ToggleButton value={2}>{currencySymbol}</ToggleButton>
        </StyledToggleButtonGroup>
    );
};

DiscountTypeGroup.propTypes = {
    currencySymbol: PropTypes.string,
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
    selected: PropTypes.number,
    visible: PropTypes.bool,
};

export default DiscountTypeGroup;
