import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        getMaxValueText,
        getMinValueText,
        MUST_BE_GREATER_THAN_ZERO,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    return object().shape({
        bag_years: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(50, getMaxValueText(50))
            .nullable(),
        generation_delay: string().required(REQUIRED_TEXT),
        proposal_plan: string().required(REQUIRED_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT).nullable(),
        type_change_offer: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0.0001, MUST_BE_GREATER_THAN_ZERO)
            .nullable(),
        discount_rate: number()
            .typeError(REQUIRED_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        ppa_price: number().when('ppa_active', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        ppa_percentage_increase: number().when('ppa_active', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_type: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(1, getMaxValueText(1))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        isr_percentage: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_annual: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 1,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(50, getMaxValueText(50))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        isr_simulation_monthly: number().when(['isr', 'isr_simulation_type'], {
            is: (isr, isr_simulation_type) =>
                isr === true && isr_simulation_type === 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(1, getMinValueText(1))
                    .max(600, getMaxValueText(600))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        opex_costing_type: string().when('opex_active', {
            is: true,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        opex: number().when(['opex_active', 'opex_costing_type'], {
            is: (opexActive, opexCostingType) =>
                opexActive === true && opexCostingType === 'by_fixed_amount',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        opex_percentage_increase: number().when(
            ['opex_active', 'opex_costing_type'],
            {
                is: (opexActive, opexCostingType) =>
                    opexActive === true &&
                    opexCostingType === 'by_fixed_amount',
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .nullable(),
                otherwise: () => string().nullable(),
            },
        ),
        kw_opex_amount: number().when(['opex_active', 'opex_costing_type'], {
            is: (opexActive, opexCostingType) =>
                opexActive === true && opexCostingType === 'by_kw_and_kwh',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        kw_opex_percentage_increase: number().when(
            ['opex_active', 'opex_costing_type'],
            {
                is: (opexActive, opexCostingType) =>
                    opexActive === true && opexCostingType === 'by_kw_and_kwh',
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .nullable(),
                otherwise: () => string().nullable(),
            },
        ),
        kwh_opex_amount: number().when(['opex_active', 'opex_costing_type'], {
            is: (opexActive, opexCostingType) =>
                opexActive === true && opexCostingType === 'by_kw_and_kwh',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        kwh_opex_percentage_increase: number().when(
            ['opex_active', 'opex_costing_type'],
            {
                is: (opexActive, opexCostingType) =>
                    opexActive === true && opexCostingType === 'by_kw_and_kwh',
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .nullable(),
                otherwise: () => string().nullable(),
            },
        ),
    });
};
