import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CostArrayRanges from './CostArrayRanges';

const TabCostRanges = ({
    control,
    currencies,
    getValues,
    isDisabled,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <CostArrayRanges
                control={control}
                currencies={currencies}
                getValues={getValues}
                isDisabled={isDisabled}
                name="cost_ranges_watt"
                setValue={setValue}
                title={t('Cost per watt')}
            />
            <CostArrayRanges
                control={control}
                currencies={currencies}
                getValues={getValues}
                isDisabled={isDisabled}
                name="cost_ranges_kwh"
                setValue={setValue}
                title={t('Cost per kWh')}
            />
        </>
    );
};

TabCostRanges.propTypes = {
    control: PropTypes.object,
    currencies: PropTypes.array,
    getValues: PropTypes.func,
    isDisabled: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TabCostRanges;
