import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from 'sunwise-ui';

const WrraperEdit = ({ children, disabledForm }) => {
    const { t } = useTranslation();

    if (disabledForm)
        return (
            <Box display="flex" justifyContent="flex-end">
                {children}
            </Box>
        );
    return (
        <Box display="flex" justifyContent="flex-end">
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    '&:hover .edit-icon': {
                        opacity: 1,
                        transform: 'translateX(0)',
                    },
                }}
            >
                <Box
                    className="edit-icon"
                    sx={{
                        position: 'absolute',
                        left: '-18px',
                        opacity: 0,
                        transform: 'translateX(-10px)',
                        transition: 'opacity 0.3s, transform 0.3s',
                    }}
                >
                    <EditIcon sx={{ fontSize: 14 }} />
                </Box>
                <Tooltip title={t('Click to edit')}>{children}</Tooltip>
            </Box>
        </Box>
    );
};

WrraperEdit.propTypes = {
    children: PropTypes.object,
    disabledForm: PropTypes.bool,
};

export default WrraperEdit;
