import React from 'react';

import { Box, Grid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const GuaranteedGenerationFields = ({ control, isDisabled = false }) => {
    const { t } = useTranslation();
    const guaranteedGenerationActive = useWatch({
        control,
        name: 'guaranteed_gen_active',
    });

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Guaranteed generation')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="guaranteed_gen_active"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isDisabled || !guaranteedGenerationActive}
                        fullWidth
                        label={t('Percentage')}
                        max={100}
                        min={0}
                        name="guaranteed_gen"
                        placeholder="90"
                    />
                </Grid>
            </Grid>
        </>
    );
};

GuaranteedGenerationFields.propTypes = {
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default GuaranteedGenerationFields;
