import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import i18next from 'i18next';
import {
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const PriceRangeDropDown = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const currencyIso = 'MXN';
    const countryCurrencyLocale = 'es-MX';
    const currencySymbol = '$';
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton onClick={handleClick} sx={{ ml: 1 }}>
                <InfoIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem>
                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {i18next.t('Min')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Max')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Cost')} ({currencyIso})
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Margin')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Final price')} (
                                        {currencyIso})
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">{1}</TableCell>
                                    <TableCell align="center">{10}</TableCell>
                                    <TableCell align="center">
                                        {numberFormat(100, {
                                            currency: currencyIso,
                                            decimals: 2,
                                            locale: countryCurrencyLocale,
                                            style: 'currency',
                                            symbol: currencySymbol,
                                        })}
                                    </TableCell>
                                    <TableCell align="center">20</TableCell>
                                    <TableCell align="center">
                                        {numberFormat(120, {
                                            currency: currencyIso,
                                            decimals: 2,
                                            locale: countryCurrencyLocale,
                                            style: 'currency',
                                            symbol: currencySymbol,
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">{11}</TableCell>
                                    <TableCell align="center">{20}</TableCell>
                                    <TableCell align="center">
                                        {numberFormat(100, {
                                            currency: currencyIso,
                                            decimals: 2,
                                            locale: countryCurrencyLocale,
                                            style: 'currency',
                                            symbol: currencySymbol,
                                        })}
                                    </TableCell>
                                    <TableCell align="center">20</TableCell>
                                    <TableCell align="center">
                                        {numberFormat(120, {
                                            currency: currencyIso,
                                            decimals: 2,
                                            locale: countryCurrencyLocale,
                                            style: 'currency',
                                            symbol: currencySymbol,
                                        })}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MenuItem>
            </Menu>
        </>
    );
};

PriceRangeDropDown.propTypes = {};

export default PriceRangeDropDown;
