import React, { useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DEFAULT_CURRENCY } from 'common/constants';
import { getCurrencySymbol } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import Generation from './Generation';

const FormContainer = ({
    anualSolarGeneration,
    averageIrradiation,
    canModifyRoiSolarDegradation,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    configCsvImporter,
    hasOfferProfiles,
    initialValues,
    isLocked,
    offerDetailsComplements,
    onSubmit,
    prepareDeleteFile,
    proposalId,
    setConfigCsvImporter,
    sunHours,
    systemSize,
}) => {
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const offerCurrency = get(
        offerDetailsComplements,
        'currency',
        DEFAULT_CURRENCY,
    );
    const currencySymbol = getCurrencySymbol(offerCurrency);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Generation
                anualSolarGeneration={anualSolarGeneration}
                averageIrradiation={averageIrradiation}
                canModifyRoiSolarDegradation={canModifyRoiSolarDegradation}
                canModifySolarGeneration={canModifySolarGeneration}
                canModifySolarGenerationFile={canModifySolarGenerationFile}
                configCsvImporter={configCsvImporter}
                control={control}
                currencySymbol={currencySymbol}
                getValues={getValues}
                handleSubmit={handleSubmit(onSubmit)}
                hasOfferProfiles={hasOfferProfiles}
                initialValues={initialValues}
                isLocked={isLocked}
                prepareDeleteFile={prepareDeleteFile}
                proposalId={proposalId}
                setConfigCsvImporter={setConfigCsvImporter}
                setValue={setValue}
                sunHours={sunHours}
                systemSize={systemSize}
            />
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    configCsvImporter: selectors.getConfigCsvImporter,
    hasOfferProfiles: proposalGeneratorOneColumnSelectors.getHasOfferProfiles,
    initialValues: selectors.getInitialValues,
    offerDetailsComplements:
        proposalGeneratorOneColumnSelectors.getOfferDetailsComplements,
});

const mapDispatchToProps = (dispatch) => ({
    prepareDeleteFile: (proposalId, values) =>
        dispatch(actions.prepareDeleteFile(proposalId, values)),
    setConfigCsvImporter: (configCsvImporter) =>
        dispatch(actions.setConfigCsvImporter(configCsvImporter)),
});

FormContainer.propTypes = {
    anualSolarGeneration: PropTypes.number,
    averageIrradiation: PropTypes.number,
    canModifyRoiSolarDegradation: PropTypes.bool,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    configCsvImporter: PropTypes.object,
    hasOfferProfiles: PropTypes.bool,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    offerDetailsComplements: PropTypes.object,
    onSubmit: PropTypes.func,
    prepareDeleteFile: PropTypes.func,
    proposalId: PropTypes.string,
    setConfigCsvImporter: PropTypes.func,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
