import i18next from 'i18next';
import {
    get,
    isArray,
    isEmpty,
    isNil,
    isNull,
    isObject,
    isString,
    round,
} from 'lodash';
import numeral from 'numeral';

import {
    CHART_COLORS,
    DISCOUNT_TYPES,
    DO_NOT_ASSIGN_VALUE,
    GOOGLE_MAPS_ADDRESS_COMPONENTS,
    PLAN_TYPES,
    STATUS,
} from 'common/constants';
import alerts from 'common/modules/alerts';
import { parseDate, isBeforeDate, isAfterDate } from 'common/utils/dates';
import {
    getIsMultibranchesAccount,
    getIsOwnerUser,
    getMainBranchOffice,
} from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import { DEFAULT_VALUES } from '../../../modules/companyBranding/constants';

const dataImageRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;

const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const dateRegex =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

export const locationRegex =
    /^([-+]?[0-9]*\.?[0-9]+),\s*([-+]?[0-9]*\.?[0-9]+)$/;

export const accesoryTypeToLabel = (accesoryType) => {
    const accesoriesType = {
        0: i18next.t('Monitoring'),
        1: i18next.t('Wire'),
        2: i18next.t('Optimizer'),
        3: i18next.t('Connector'),
        4: i18next.t('Transformer'),
    };
    return accesoriesType[accesoryType];
};

export const addDefaultSrc = (e, width, height) =>
    (e.target.src = getPlaceholderImg(width, height));

export const arraytoDictionary = (array, key) => {
    if (!Array.isArray(array)) return {};
    return array.reduce(
        (acc, current) => ({ ...acc, [current[key]]: current }),
        {},
    );
};

export const assignBranchOffice = (branchOffices) => {
    if (!getIsMultibranchesAccount() || getIsOwnerUser())
        return getMainBranchOffice();
    return branchOffices[0]?.id;
};

export const buildGraphsColors = ({ type, stacked_bar }) => {
    const colorList = CHART_COLORS;
    const tempColorList = [...colorList];
    let keyList = [];
    if (
        stacked_bar &&
        stacked_bar.data &&
        stacked_bar.data.length > 0 &&
        stacked_bar.visible &&
        type === 'bar'
    ) {
        keyList = Object.keys(
            JSON.parse(stacked_bar.data[0][stacked_bar.selected]),
        );
    }
    const results = keyList.map((o, index) => {
        const colorListIndex =
            index -
            parseInt(index / tempColorList.length) * tempColorList.length;

        return tempColorList[colorListIndex];
    });
    return results;
};

export const calculateFinalCost = (cost, margin) => {
    const value = parseFloat(cost) / (1 - parseFloat(margin) / 100) || 0;
    return value.toFixed(getDecimals(value));
};

export const calculateMargin = (finalCost, cost) => {
    const value =
        (100 * (parseFloat(finalCost) - parseFloat(cost))) /
            parseFloat(finalCost) || 0;
    return value.toFixed(2);
};

export const calculateRangesPriceFrom = (lastTo) => parseInt(lastTo) + 1;

export const calculateRangesPriceTo = (from) => parseInt(from);

export const calculateRangesPriceToByNextRange = (nextRange) =>
    parseInt(nextRange.price_from) - 1;

export const convertCurrency = ({
    amount,
    exchangeRate,
    fromCurrency,
    toCurrency,
}) => {
    if (fromCurrency === toCurrency) return amount || 0;
    if (fromCurrency === 'USD') return amount * exchangeRate || 0;
    return amount / exchangeRate || 0;
};

export const costingChoiceToLabel = (costingChoice) => {
    const costingChoices = {
        0: i18next.t('Per panel'),
        1: i18next.t('Per inverter'),
        2: i18next.t('Per Watt'),
        3: i18next.t('Per installation'),
    };
    return costingChoices[costingChoice];
};

export const countDecimals = (value) => {
    const newValue = parseExponentialNumber(value);
    let decimalsNumber = 0;
    if (!isNaN(parseFloat(newValue))) {
        const parts = newValue.toString().split('.');
        if (parts[1]) decimalsNumber = parts[1].length;
        return decimalsNumber;
    }
    return decimalsNumber;
};

export const cleanJSON = (str) => str.replace(/(\r\n|\n|\r|\t)/gm, ' ');

export const cloneElement = (value) => {
    let ret = value instanceof Array ? [] : {};
    for (let key in value) {
        if (!Object.prototype.hasOwnProperty.call(value, key)) continue;
        let val = value[key];
        if (val && typeof val == 'object') val = cloneElement(val);
        ret[key] = val;
    }
    return ret;
};

export const dictionaryToArray = (dictionary) =>
    Object.keys(dictionary).map((key) => dictionary[key]);

export const showReponseErrorsAsAlert = (dispatch, response) => {
    let errors = [];
    if (response && Object.prototype.hasOwnProperty.call(response, 'data')) {
        if (response.data.errors) {
            if (isArray(response.data.errors)) {
                errors = response.data.errors.map((error) => `${error}`);
            } else if (isString(response.data.errors)) {
                errors = response.data.errors.split();
            } else if (isObject(response.data.errors)) {
                Object.keys(response.data.errors).forEach((key) => {
                    if (isArray(response.data.errors[key])) {
                        errors = [
                            ...errors,
                            `${key} ${response.data.errors[key][0]}`,
                        ];
                    }
                    if (isString(response.data.errors[key])) {
                        errors = [...errors, response.data.errors[key]];
                    }
                });
            }
        }
    }
    if (errors.length > 0) {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: errors,
            title: i18next.t('Error'),
            type: alerts.ALERT_TYPE_ALERT,
            variant: 'warning',
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        showToast({
            type: 'danger',
            body: i18next.t('An error occurred during the process'),
        });
    }
};

export const showFastApiErrorsAsAlert = (dispatch, response) => {
    const errorDetail = response?.data?.detail;
    if (typeof errorDetail === 'string') {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: [errorDetail],
            title: i18next.t('Error'),
            type: alerts.ALERT_TYPE_ALERT,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    } else {
        showToast({
            type: 'danger',
            body: i18next.t('An error occurred during the process'),
        });
    }
};

export const emailTypeToLabel = (emailType) => {
    const emailsType = {
        0: i18next.t('Job'),
        1: i18next.t('Personal'),
        2: i18next.t('Other'),
    };
    return emailsType[emailType];
};

export const formatAmount = (input, suffix = '$', format = '0,0.00') =>
    !input ? suffix + '0' : suffix + numeral(round(input, 2)).format(format);

export const fullNameBuild = ({ firstName, lastName, secondSurname }) => {
    let fullName = '';
    if (firstName) fullName += firstName;
    if (lastName) {
        if (fullName.length > 0) fullName += ' ';
        fullName += lastName;
    }
    if (secondSurname) {
        if (fullName.length > 0) fullName += ' ';
        fullName += secondSurname;
    }
    return fullName;
};

export const formatNumber = ({
    format = '0,0',
    input,
    suffix = '',
    unit = '',
}) =>
    !input
        ? suffix + '0'
        : `${suffix}${numeral(input).format(format, (n) => n)}${unit}`;

export const generateMapUrl = ({
    lat,
    lng,
    zoom = 15,
    width = 800,
    height = 600,
}) => {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${
        import.meta.env.VITE_GOOGLE_MAPS_KEY
    }`;
};

export const getAddresFormatted = (projectLocation) => {
    if (!isNull(projectLocation?.description)) {
        return `${projectLocation?.description || ''} ${
            projectLocation?.city || ''
        } ${projectLocation?.political_division1 || ''} ${
            projectLocation?.political_division2 || ''
        } ${projectLocation?.country || ''} ${projectLocation?.zip_code || ''}`;
    }
};

export const getAgentOptions = (agents) => {
    const items = agents
        .filter((agent) => agent.is_active && agent.is_enabled)
        .map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            };
        });

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: '',
        },
        {
            label: i18next.t('No user assigned'),
            value: DO_NOT_ASSIGN_VALUE,
        },
        ...items,
    ];
};

export const getAvailableCatalogs = (catalogs, proposalsNumber) =>
    catalogs.filter((item) => item.order <= proposalsNumber);

export const getCurrencyById = (currencies, id) =>
    currencies.find((item) => item.id === id);

export const getCatalogsToSelect = (catalogs) => {
    const items = catalogs.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    return [
        {
            disabled: true,
            label: i18next.t('Select proposal'),
            value: '',
        },
        ...items,
    ];
};

export const getColorButtonByStatus = (status) => {
    switch (status) {
        case STATUS.REQUESTED_STATUS.key:
            return '#fc983a';
        case STATUS.INCOMPLETE_STATUS.key:
            return '#848bab';
        case STATUS.CANCELLED_STATUS.key:
            return '#d24649';
        case STATUS.REJECTED_STATUS.key:
            return '#d24649';
        case STATUS.INPROCESS_STATUS.key:
            return '#1a5459';
        case STATUS.PRE_APPROVED_STATUS.key:
            return '#4ec09c';
        case STATUS.APPROVED_STATUS.key:
        case STATUS.CONDITIONED_APPROVED_STATUS.key:
            return '#54c461';
        case STATUS.CLOSED_STATUS.key:
            return '#373fb0';
        default:
            return '#011e2e';
    }
};

export const getCurrenciesToSelect = (currencies, isFetching) => {
    const items = currencies.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    return [
        {
            label: isFetching
                ? i18next.t('Loading currency', { count: 2 }).concat('...')
                : i18next.t('Select currency'),
            value: '',
            disabled: true,
        },
        ...items,
    ];
};

export const getCurrencyIso = (currency) => {
    if (
        !isEmpty(currency) &&
        !isNull(currency) &&
        hasValue(currency, 'abbreviation')
    )
        return get(currency, 'abbreviation', 'USD');
    return 'USD';
};

export const getCurrencyLocale = (currency) => {
    if (!isEmpty(currency) && !isNull(currency) && hasValue(currency, 'locale'))
        return get(currency, 'locale', 'en-US').replace(/_/g, '-');
    return 'en-US';
};

export const getCurrencySymbol = (currency) => {
    if (!isEmpty(currency) && !isNull(currency))
        return get(currency, 'symbol', '$');
    return '$';
};

export const getDataFromGeocode = (results, type) => {
    if (results) {
        let data = {};
        results.forEach((item) => {
            if (item.types.includes(type)) data = item;
        });
        return data;
    }
    return { formatted_address: '' };
};

export const getDayLabel = (day) => {
    switch (day) {
        case 'Monday':
            return i18next.t('Monday');
        case 'Tuesday':
            return i18next.t('Tuesday');
        case 'Wednesday':
            return i18next.t('Wednesday');
        case 'Thursday':
            return i18next.t('Thursday');
        case 'Friday':
            return i18next.t('Friday');
        case 'Saturday':
            return i18next.t('Saturday');
        default:
            return i18next.t('Sunday');
    }
};

export const getDecimals = (value) => {
    if (!value) return 0;
    const decimals = countDecimals(value);
    if (decimals <= 1) return 1;
    if (decimals >= 2 && decimals <= 4) return decimals;
    return 4;
};

export const getDiscountValidationMessage = (
    discountType,
    maximumDiscount = 0,
    unitPrice,
) => {
    if (discountType === DISCOUNT_TYPES.AMOUNT) {
        const value = getMaxDiscountByUnitPrice(maximumDiscount, unitPrice);
        return `${i18next.t('Discount')} ${i18next
            .t('It cannot be greater than {{value}}', { value })
            .toLowerCase()}`;
    }
    return `${i18next.t('Discount')} ${i18next
        .t('It cannot be greater than {{value}}', { value: maximumDiscount })
        .toLowerCase()}`;
};

export const getHiddenColumns = (tableSettings) => {
    return tableSettings
        .filter((item) => !item.is_active)
        .map(({ name }) => name);
};

export const getIsLocalRedirect = (path) => !/^http/i.test(path);

export const getItemMeta = (id, items) => {
    const item = items.find((item) => item.value === id);
    return !item ? 0 : item;
};

export const getItemUnitPrice = (id, items) => {
    const item = items.find((item) => item.value === id);
    return !item ? 0 : item.unit_price;
};

export const getLabelWithCurrency = (iso, label) => {
    if (iso) return `${label} (${iso})`;
    return label;
};

export const getMaxDiscountByUnitPrice = (maximumDiscount, unitPrice) => {
    if (maximumDiscount < 100) return unitPrice * (1 - maximumDiscount / 100);
    return unitPrice;
};

export const getMonthLabel = (day) => {
    switch (day) {
        case 'Jan':
            return i18next.t('Jan');
        case 'Feb':
            return i18next.t('Feb');
        case 'Mar':
            return i18next.t('Mar');
        case 'Apr':
            return i18next.t('Apr');
        case 'May':
            return i18next.t('May');
        case 'Jun':
            return i18next.t('Jun');
        case 'Jul':
            return i18next.t('Jul');
        case 'Aug':
            return i18next.t('Aug');
        case 'Sep':
            return i18next.t('Sep');
        case 'Oct':
            return i18next.t('Oct');
        case 'Nov':
            return i18next.t('Nov');
        default:
            return i18next.t('Dec');
    }
};

export const getNotificationPath = (notification) => {
    const parameters = get(notification, 'data.parameters', []);
    let path = '/' + get(notification, 'data.path', '#');
    if (getIsLocalRedirect(path)) {
        parameters.forEach((item) => {
            path = path.replace(`{${item.key}}`, item.value);
        });
    }
    return path;
};

export const getOppositeCurrency = (currencies) =>
    currencies.find((item) => item.abbreviation !== 'USD');

export const getOrderedColumns = (tableSettings) => {
    return tableSettings.reduce((acc, col) => {
        return {
            ...acc,
            [col.name]: col.order,
        };
    }, {});
};

export const getPhoneCodeOptions = (codes) => {
    let data = [];
    if (!isEmpty(codes)) {
        data = Object.keys(codes).map((key) => ({
            label: `(${key}) ${codes[key]}`,
            value: codes[key],
        }));
    }
    return [
        {
            value: '',
            label: i18next.t('Select'),
            disabled: true,
        },
        ...data,
    ];
};

export const getPlaceholderImg = (
    width = 100,
    height = 0,
    text = i18next.t('No image'),
) =>
    `https://placehold.jp/${width}x${
        height === 0 ? width : height
    }.png?text=${text}`;

export const getPriceByWatt = (
    currencyIso,
    subtotal,
    systemSize,
    typeChange,
) => {
    if (currencyIso !== 'USD')
        return subtotal / (systemSize * 1000) / typeChange;
    return subtotal / (systemSize * 1000);
};

export const getPolygonCoordinates = (fieldSegmentPoints) => {
    if (!isArray(fieldSegmentPoints)) return [];
    return fieldSegmentPoints.map((point) => [
        point.y_coordinate,
        point.x_coordinate,
    ]);
};

export const getProductBrandName = ({ brand_name }) =>
    brand_name || i18next.t('No brand');

export const getSolarModules = (solarModules) => {
    if (!isArray(solarModules)) return [];
    return solarModules.map((solarModule) => {
        return solarModule.solar_module_points.map((point) => {
            return [point.y_coordinate, point.x_coordinate];
        });
    });
};

export const getNewSolarModules = (solarModules) => {
    if (!isArray(solarModules)) return [];
    return solarModules.map((solarModule) => ({
        cell: solarModule.cell,
        col: solarModule.col,
        enabled: solarModule.is_enable,
        group: solarModule.group,
        id: solarModule.id,
        path: JSON.parse(solarModule.solar_modules).map((point) => [
            point.y,
            point.x,
        ]),
        row: solarModule.row,
    }));
};

export const getSegmentArea = (google, polygon) => {
    if (!polygon.length) return 0;
    return google.maps.geometry.spherical.computeArea(
        polygon.map((coords) => new google.maps.LatLng(coords[0], coords[1])),
    );
};

export const getSourceName = (id, availableSources) => {
    const source = availableSources.find((item) => item.value === id);
    return source?.label || '';
};

export const getStatusProposalLabel = (isApproved, isGenerated) => {
    if (isApproved) return i18next.t('Approved', { context: 'female' });
    if (isGenerated) return i18next.t('Generated', { context: 'female' });
    return i18next.t('Draft');
};

export const getSubTotal = (
    discount = 0,
    discount_type,
    final_cost,
    quantity,
) => {
    if (discount === 0) return final_cost * quantity;
    if (discount_type === 0)
        return final_cost * quantity * (1 - discount / 100);
    return final_cost * quantity - discount;
};

export const getTrackingColor = (tracking_date) => {
    const today = new Date();
    const tracking_date_formatted = parseDate(tracking_date, 'MM/dd/yyyy');
    if (isAfterDate(tracking_date_formatted, today)) return '#02E675';
    if (isBeforeDate(tracking_date_formatted, today)) return '#FA6968';
    return '#CBEB04';
};

export const getTranslatedAddonLabel = (addon) => {
    switch (addon) {
        case 'Cotizador-Web':
            return i18next.t('Web quoter');
        case 'Modulo-Sunwise-Analytics':
            return i18next.t('Analytics');
        case 'Sunwise-After-Sales':
            return i18next.t('After sales');
        case 'multi-branchs':
            return i18next.t('Multi-branch office', { count: 2 });
        case 'TS-PS':
            return i18next.t('Time shifting and peak saving');
        default:
            return '';
    }
};

export const handleClickViewMode = (setViewMode, value) => {
    setViewMode(value);
    localStorage.setItem('proposer_view_mode', value);
};

export const handleFileURL = (url, preffix) => {
    const pattern = /^((https):\/\/)/;
    return pattern.test(url) ? url : `${preffix}${url}`;
};

export const handleWizardTierCost = (plan, quantity, formatted = true) => {
    const tier = plan.tiers.find((item) => item.up_to === quantity);
    const unit_amount =
        tier?.unit_amount ??
        plan.tiers.find((item) => item.up_to === null)?.unit_amount ??
        0;

    if (!formatted) return (quantity * unit_amount) / 100;
    return (
        ((quantity * unit_amount) / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: plan.currency,
        }) +
        ' ' +
        plan.currency.toUpperCase()
    );
};

export const hasValue = (object, name) =>
    Object.prototype.hasOwnProperty.call(object, name) &&
    !isEmpty(object[name]);

export const inverterTypeToLabel = (panelType) => {
    const panelTypes = {
        0: i18next.t('Central'),
        1: i18next.t('String'),
        2: i18next.t('Micro inverter'),
        3: i18next.t('String-OPT'),
    };
    return panelTypes[panelType];
};

export const isEmail = (text) => emailRegex.test(String(text).toLowerCase());

export const isEmptyText = (value = '') => value && value.length === 0;

export const isCompressedFileSupported = (pathName) =>
    ['zip', 'rar'].includes(getFileExtensionByPath(pathName).toLowerCase());

export const isFile = (value) =>
    isArray(value) &&
    !isEmpty(value) &&
    Object.prototype.toString.call(value[0]) === '[object File]';

export const isImageFileSupported = (pathName) =>
    ['png', 'jpeg', 'jpg'].includes(
        getFileExtensionByPath(pathName).toLowerCase(),
    );

export const isImageBase64 = (string) => string.match(dataImageRegex);

export const isImageUrl = (string) => string.match(urlRegex);

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const itemsToSelectWithoutDuplicates = (
    id,
    formValues,
    itemsToSelect,
    groupName = 'items',
) => {
    const selected = formValues[groupName].map((item) => item.item);
    return itemsToSelect.filter(
        (item) =>
            selected.indexOf(item.value) === -1 ||
            item.value === id ||
            item.value === 'undefined' ||
            item.value === '',
    );
};

export const itemsToSelectRHFWithoutDuplicates = (
    id,
    values,
    itemsToSelect,
) => {
    const selected = values.map((item) => item.item);
    const newItemsToSelect = itemsToSelect.map((item) => {
        if (
            selected.indexOf(item.value) === -1 ||
            item.value === id ||
            item.value === 'undefined' ||
            item.value === ''
        )
            return { ...item, disabled: item?.disabled || false };
        return { ...item, disabled: true };
    });
    newItemsToSelect[0].disabled = true;
    return newItemsToSelect;
};

export const itemsToSelect2WithoutDuplicates = (
    itemsToSelect,
    isNewProduct = false,
    offerItems = [],
) => {
    const itemsToSelectFiltered = isNewProduct
        ? itemsToSelect.filter((item) => item.show_product)
        : itemsToSelect;
    const itemsToSelectFilteredIds = itemsToSelectFiltered.map(
        (equipment) => equipment.id,
    );
    const elementSelectedCompareList = offerItems.reduce(
        (acc, current) => {
            if (!itemsToSelectFilteredIds.includes(current.id))
                return { ...acc, excluded: [...acc.excluded, current] };
            return { ...acc, included: [...acc.included, current] };
        },
        {
            included: [],
            excluded: [],
        },
    );
    const selectedIds = elementSelectedCompareList.included.map(
        (item) => item.id,
    );
    const offerItemsIds = offerItems.map((item) => item.id);
    const tempFilteredEquipments = itemsToSelectFiltered
        .filter(
            (equipment) =>
                !['Eliminado', 'Desactivado'].includes(
                    equipment.status_product,
                ) || selectedIds.includes(equipment.id),
        )
        .reduce((acc, current) => {
            const brandName = getProductBrandName(current);
            const isDisabled =
                ['Eliminado', 'Desactivado'].includes(current.status_product) ||
                offerItemsIds.includes(current.id);
            const option = {
                label: current.name,
                value: current.id,
                disabled: isDisabled,
            };
            if (!acc[brandName])
                return {
                    ...acc,
                    [brandName]: {
                        label: brandName,
                        options: [option],
                    },
                };
            return {
                ...acc,
                [brandName]: {
                    ...acc[brandName],
                    options: [...acc[brandName].options, option],
                },
            };
        }, {});
    let ordered = {};
    Object.keys(tempFilteredEquipments)
        .sort()
        .forEach((key) => {
            ordered[key] = tempFilteredEquipments[key];
            ordered[key].options = tempFilteredEquipments[key].options.sort();
        });
    if (elementSelectedCompareList.excluded.length > 0) {
        ordered.equipment_deleted_in_db = {
            label: i18next.t('Other', { count: 2 }),
            options: elementSelectedCompareList.excluded.map((item) => ({
                label: item.name,
                value: item.id,
                disabled: true,
            })),
        };
    }
    return Object.values(ordered);
};

export const nextItemsToSelect = (itemsSelected = [], itemsToSelect) => {
    const selectedItemsSet = new Set(itemsSelected.map((item) => item.item));

    return itemsToSelect.filter(
        (itemToSelect) =>
            itemToSelect.value !== '' &&
            !selectedItemsSet.has(itemToSelect.value),
    );
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeText = (text) =>
    text
        .split(' ')
        .map((word) => capitalize(word))
        .join(' ');

export const numberFormat = (value, options) => {
    const newValue = parseExponentialNumber(value);
    const {
        currency,
        decimals = getDecimals(value),
        locale = 'en-US',
        unit = '',
        style,
    } = options;
    let formatted = newValue;
    let unitText = '';

    switch (style) {
        case 'currency':
            formatted = new Intl.NumberFormat(locale, {
                style,
                currency,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            break;
        case 'decimal':
            formatted = new Intl.NumberFormat(locale, {
                style,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            unitText = unit;
            break;
        default:
            formatted = newValue;
            break;
    }

    if (isEmpty(unitText)) return formatted;
    return `${formatted} ${unitText}`;
};

export const originTypeToLabel = (originType) => {
    const originTypes = {
        RECOM: i18next.t('Recommendation'),
        FACE: 'Facebook',
        INST: 'Instagram',
        WEBPAG: i18next.t('Web page'),
        SPEC: i18next.t('Spectacular'),
        FLYER: i18next.t('Flyer', { count: 2 }),
        OTHER: i18next.t('Other'),
    };

    return originTypes[originType];
};

export const panelTypeToLabel = (panelType) => {
    const panelTypes = {
        0: i18next.t('Monocrystalline'),
        1: i18next.t('Polycrystalline'),
        2: i18next.t('Thin Film'),
    };

    return panelTypes[panelType];
};

export const parseExponentialNumber = (value) => {
    if (!value) return 0;
    if (!(value.toString().indexOf('e') !== -1)) return value;
    const parts = value.toString().split('e-');
    if (parts[1]) {
        if (parts[1] > 100) return 0;
        return value.toFixed(parts[1]);
    }
    return value;
};

export const parseJSON = (value) =>
    JSON.parse(value?.replace(/'/gi, '"') || null);

export const pluralizeText = (number, text, addText = 's') =>
    number !== 1 ? `${text}${addText}` : text;

const stringToColor = (string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

export const stringAvatar = (name) => {
    const nameArray = name.split(' ');
    let newName = '';
    if (nameArray.length === 1) newName = nameArray[0][0];
    else if (nameArray.length >= 2 && nameArray[1][0])
        newName = `${nameArray[0][0]}${nameArray[1][0]}`;
    else newName = nameArray[0][0];
    return {
        sx: { bgcolor: stringToColor(name) },
        children: newName?.toUpperCase(),
    };
};

export const telephoneTypeToLabel = (telephoneType) => {
    const telephonesType = {
        0: i18next.t('Mobile'),
        1: i18next.t('Office'),
        2: i18next.t('Home'),
        3: i18next.t('Other'),
    };
    return telephonesType[telephoneType];
};

export const toFixed = (number, fixed) => {
    const fixedValue = Math.pow(10, fixed);
    return parseInt(number * fixedValue) / fixedValue;
};

export const trackingTypeToLabel = (trackingType) => {
    const trackingTypes = {
        0: i18next.t('Call'),
        1: i18next.t('Mail'),
        2: i18next.t('Visit'),
        3: i18next.t('Message'),
        4: i18next.t('Other'),
    };
    return trackingTypes[trackingType];
};

export const truncateString = (text, maxLength) => {
    return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
};

export const getFileExtensionByPath = (url) => {
    if (!url) return '';
    if (url.indexOf('.', url.lastIndexOf('/') + 1) === -1) return '';
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const getFileNameByPath = (url) =>
    url.substring(url.lastIndexOf('/') + 1);

export const getAlgorithmTypes = (isBright = false, isSerfimex = false) => {
    let options = [
        {
            label: i18next.t('Simple Credit (Ally)'),
            value: 'static_payment',
        },
        {
            label: i18next.t('Financial leasing'),
            value: 'static_payment_tax',
        },
        // {
        //     label: i18next.t('Finance lease with variable payment'),
        //     value: 'static_capital_payment',
        // },
    ];
    if (isBright)
        return [
            ...options,
            {
                label: i18next.t('Leasing with Solver'),
                value: 'solver_payment',
            },
        ];
    if (isSerfimex)
        return [
            ...options,
            {
                label: 'API Key',
                value: 'static_api_key',
            },
        ];

    return options;
};

export const getTranslatedStatusValue = (status) => {
    const findedStatus = Object.values(STATUS).find((s) => s.key === status);
    return findedStatus ? findedStatus.value : 'N/D';
};

export const batteryTypeToLabel = (panelType) => {
    const panelTypes = {
        0: i18next.t('Unit'),
        1: i18next.t('Kit'),
    };
    return panelTypes[panelType];
};

export const getDefaultProjectLocation = (contactLocation, companyPosition) => {
    if (!isEmpty(contactLocation) && !isEmpty(contactLocation?.position))
        return {
            lat: get(contactLocation, 'position.latitude'),
            lng: get(contactLocation, 'position.longitude'),
        };
    return { ...companyPosition };
};

export const getDefaultProposalLocation = (
    projectLocation,
    contactLocation,
    companyPosition,
) => {
    if (!isEmpty(projectLocation) && !isEmpty(projectLocation?.position))
        return {
            lat: get(projectLocation, 'position.latitude'),
            lng: get(projectLocation, 'position.longitude'),
        };
    if (!isEmpty(contactLocation) && !isEmpty(contactLocation?.position))
        return {
            lat: get(contactLocation, 'position.latitude'),
            lng: get(contactLocation, 'position.longitude'),
        };
    return { ...companyPosition };
};

export const getDefaultName = ({ arrayItems, preffix = null, projectName }) => {
    let name = '';
    const baseName = preffix ? `${preffix} - ${projectName}` : projectName;
    const namesSet = new Set(arrayItems.map((item) => item.name));

    for (let i = 0; i <= arrayItems.length; i++) {
        const testName = i === 0 ? baseName : `${baseName} (${i})`;
        if (!namesSet.has(testName)) {
            name = testName;
            break;
        }
    }
    return name;
};

const validateRGB = (key, values) => {
    const value = values[key];
    if (!value) return DEFAULT_VALUES[key];
    if (value[0] !== '#') return `#${value}`;
    return value;
};

export const handleBuildTheme = ({
    baseDarkPalette,
    baseLightPalette,
    branding,
    defaultValues = DEFAULT_VALUES,
}) => {
    if (!branding || isEmpty(branding))
        return { darkPalette: baseDarkPalette, lightPalette: baseLightPalette };
    const values = { ...defaultValues, ...branding };
    const _lightPalette = {
        primary: {
            contrastText: validateRGB(
                'light_primary_contrast_text_color',
                values,
            ),
            main: validateRGB('light_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'light_secondary_contrast_text_color',
                values,
            ),
            main: validateRGB('light_secondary_color', values),
        },
    };

    const _darkPalette = {
        primary: {
            contrastText: validateRGB(
                'dark_primary_contrast_text_color',
                values,
            ),
            main: validateRGB('dark_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'dark_secondary_contrast_text_color',
                values,
            ),
            main: validateRGB('dark_secondary_color', values),
        },
    };

    return {
        darkPalette: { ...baseDarkPalette, ..._darkPalette },
        lightPalette: { ...baseLightPalette, ..._lightPalette },
    };
};

export const parseClipboardData = (data) => {
    if (isNil(data) || isEmpty(data)) return [];
    return data
        .trim()
        .split(/\r\n|\n|\r/)
        .map((row) => row.split('\t'));
};

export const handleFaviconAndTitle = ({
    isGuest,
    userCompany,
    subscription,
} = {}) => {
    if (isGuest || isEmpty(userCompany) || isEmpty(subscription)) return;

    const subscriptionType = get(subscription, 'type.key', null);
    const isEnterprise = subscriptionType === PLAN_TYPES.ENTERPRISE;

    if (!isEnterprise) return;

    const storageBranding = localStorage.getItem('branding');

    if (storageBranding && storageBranding !== 'undefined') {
        const branding = JSON.parse(storageBranding);
        const { logotype_image } = branding;
        if (logotype_image) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = logotype_image;
        }
    }

    const { business_name } = userCompany;
    document.title = business_name;
};

export const handleDownloadSunwiseFile = async (url, fileName) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.blob();
        const downloadUrl = window.URL.createObjectURL(data);
        const a = document.createElement('a');

        a.href = downloadUrl;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Download failed:', error);
    }
};

// Utility function to calculate luminance
export const getLuminance = (color) => {
    let rgb;

    if (color.startsWith('#')) {
        // Hex color
        rgb = color.match(/\w\w/g).map((c) => Number.parseInt(c, 16));
    } else if (color.startsWith('rgba')) {
        // RGBA color
        rgb = color
            .match(/(\d+(\.\d+)?)/g)
            .slice(0, 3)
            .map(Number);
    } else if (color.startsWith('rgb')) {
        // RGB color
        rgb = color.match(/(\d+(\.\d+)?)/g).map(Number);
    } else {
        throw new Error('Unsupported color format');
    }

    const [r, g, b] = rgb.map((c) => {
        c /= 255;
        return c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
    });

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// Utility function to calculate contrast ratio
const getContrastRatio = (lum1, lum2) => {
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
};

// Utility function to get contrasting text color with required contrast ratio
export const getContrastingTextColor = (backgroundColor) => {
    const blackColor = '#000000';
    const whiteColor = '#ffffff';

    const backgroundLuminance = getLuminance(backgroundColor);
    const whiteLuminance = getLuminance(whiteColor);
    const blackLuminance = getLuminance(blackColor);

    const contrastWithWhite = getContrastRatio(
        backgroundLuminance,
        whiteLuminance,
    );
    const contrastWithBlack = getContrastRatio(
        backgroundLuminance,
        blackLuminance,
    );

    return contrastWithWhite > contrastWithBlack ? whiteColor : blackColor;
};

export const handleOnChangeLatLng = ({
    latField = 'latitude',
    lngField = 'longitude',
    setValue,
    value,
}) => {
    const regex = locationRegex;
    const match = value.match(regex);
    if (match) {
        const [, lat, lng] = match;
        setValue(latField, lat);
        setValue(lngField, lng);
    } else {
        setValue(latField, null);
        setValue(lngField, null);
    }
};

const getAddressComponentNameByType = (type, components) => {
    if (!components?.length) return '';

    const component = components.find((component) =>
        component.types.includes(type),
    );

    return component?.long_name || '';
};

export const getAddressData = (address_components) => {
    const getValue = (type) =>
        getAddressComponentNameByType(type, address_components);

    return {
        street: getValue(GOOGLE_MAPS_ADDRESS_COMPONENTS.STREET),
        external_house_number: getValue(
            GOOGLE_MAPS_ADDRESS_COMPONENTS.STREET_NUMBER,
        ),
        internal_house_number: getValue(
            GOOGLE_MAPS_ADDRESS_COMPONENTS.INTERIOR_NUMBER,
        ),
        neighborhood:
            getValue(GOOGLE_MAPS_ADDRESS_COMPONENTS.NEIGHBORHOOD) ||
            getValue(GOOGLE_MAPS_ADDRESS_COMPONENTS.NEIGHBORHOOD_LEVEL_1),
    };
};
