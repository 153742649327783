import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, props) => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    if (props.onlySubtotal)
        return object().shape({
            subtotalWithDiscount: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(props.subtotal, getMaxValueText(props.subtotal))
                .nullable(),
        });

    return object().shape({
        discount_amount: number().when('type_discount', {
            is: 2,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(props.subtotal, getMaxValueText(props.subtotal))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        discount_percentage: number().when('type_discount', {
            is: 0,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(100, getMaxValueText(100))
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
    });
};
