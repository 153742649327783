import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const RoiFields = ({
    canModifyRoiTaxIncentive,
    control,
    isLocked,
    setValue,
}) => {
    const { t } = useTranslation();
    const [isrActive, isrSimulationType] = useWatch({
        control,
        name: ['isr', 'isr_simulation_type'],
    });

    useEffect(() => {
        setValue('isr_simulation_annual', '');
        setValue('isr_simulation_monthly', '');
    }, [isrSimulationType, setValue]);

    const renderIsrSimulation = () => {
        if (parseInt(isrSimulationType) === 1) {
            return (
                <ReactHookFormIntlNumberInput
                    append={t('Year', {
                        count: 2,
                    })}
                    control={control}
                    disabled={
                        isLocked || !isrActive || !canModifyRoiTaxIncentive
                    }
                    fullWidth
                    id="isr_simulation_annual"
                    label={t('Simulation time')}
                    min={1}
                    max={50}
                    name="isr_simulation_annual"
                    step="any"
                />
            );
        }
        return (
            <ReactHookFormIntlNumberInput
                append={t('Month', {
                    count: 2,
                })}
                control={control}
                disabled={isLocked || !isrActive || !canModifyRoiTaxIncentive}
                fullWidth
                id="isr_simulation_monthly"
                label={t('Simulation time')}
                min={1}
                max={600}
                name="isr_simulation_monthly"
                step="any"
            />
        );
    };

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Tax incentive')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isLocked || !canModifyRoiTaxIncentive}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="isr"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={
                            isLocked || !isrActive || !canModifyRoiTaxIncentive
                        }
                        fullWidth
                        label={t('Percentage')}
                        name="isr_percentage"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    {renderIsrSimulation()}
                </Grid>
                <Grid item xs={18} md={4}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={
                            isLocked || !isrActive || !canModifyRoiTaxIncentive
                        }
                        exclusive
                        fullWidth
                        onChange={(e) => {
                            setValue('isr_simulation_type', e.target.value);
                            if (parseInt(isrSimulationType) === 1)
                                setValue('isr_simulation_monthly', '');
                            else setValue('isr_simulation_annual', '');
                        }}
                        value={parseInt(isrSimulationType)}
                        size="small"
                        sx={{ mb: 2 }}
                    >
                        <ToggleButton value={0}>{t('Monthly')}</ToggleButton>
                        <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>
    );
};

RoiFields.propTypes = {
    canModifyRoiTaxIncentive: PropTypes.bool,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default RoiFields;
