import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import {
    itemsToSelectRHFWithoutDuplicates,
    nextItemsToSelect,
} from 'common/utils/helpers';

const ReactHookFormArray = ({
    control,
    disabled,
    getValues,
    groupName,
    itemsToSelect,
}) => {
    const { t } = useTranslation();

    const { append, fields, remove } = useFieldArray({
        control: control,
        name: groupName,
    });

    const _disabled =
        disabled ||
        nextItemsToSelect(getValues(groupName), itemsToSelect).length === 0;

    return (
        <>
            {fields.map((item, i) => (
                <Box display="flex" gap="16px" key={item.id}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        name={`${groupName}.${i}.item`}
                        options={itemsToSelectRHFWithoutDuplicates(
                            item.id,
                            getValues(groupName),
                            itemsToSelect,
                        )}
                    />
                    <Box>
                        <IconButton
                            disabled={disabled}
                            onClick={() => remove(i)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ))}

            <Button
                disabled={_disabled}
                onClick={() => append({ item: '' })}
                type="button"
                variant="outlined"
            >
                {t('Add')}
            </Button>
        </>
    );
};

ReactHookFormArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    groupName: PropTypes.string,
    itemsToSelect: PropTypes.array,
};

export default ReactHookFormArray;
