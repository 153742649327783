import React, { useContext, useEffect } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';
import { getMaximumDiscount } from 'common/utils/helpers/session';

import * as proposalGeneratorOneColumnSelectors from '../proposalGeneratorOneColumn/selectors';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import { getPriceByWattMin, getSubtotalMin } from './helpers';
import * as selectors from './selectors';

const Card = ({
    closeModal,
    currencies,
    currencyIso,
    currencySymbol,
    customerMode,
    fetchOfferComplements,
    getPermissionsByCode,
    initialize,
    isFetchingOfferComplements,
    isFetchingProposalCatalogs,
    isLoading,
    isLocked = false,
    isOpenModal,
    isSaving,
    nextRateData,
    offerComplements,
    plans,
    productsTotals,
    proposalId,
    systemSize,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isOpenModal) fetchOfferComplements(proposalId);
    }, [isOpenModal]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isSaving]);

    const typeChangeOffer = get(offerComplements, 'type_change_offer', 0);
    const { subtotal } = productsTotals;
    const maximumDiscount = getMaximumDiscount();

    const subtotalMin = getSubtotalMin(maximumDiscount, subtotal);

    const priceByWattMin = getPriceByWattMin(
        currencyIso,
        maximumDiscount,
        subtotal,
        systemSize,
        typeChangeOffer,
    );

    useEffect(() => {
        if (!isEmpty(offerComplements)) initialize(offerComplements);
    }, [offerComplements]);

    const { canModify: canModifyCurrencyType } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_CURRENCY_TYPE_PERMISSION,
    );
    const { canModify: canModifyExchangeType } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_EXCHANGE_TYPE_PERMISSION,
    );
    const { canModify: canModifyPlans } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PLAN_PERMISSION,
    );
    const { canModify: canModifyRoiTaxIncentive } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_ROI_TAX_INCENTIVE_PERMISSION,
    );
    const { canModify: canModifyStockExchange } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_STOCK_EXCHANGE_PERMISSION,
    );

    const isLockedForm =
        isFetchingOfferComplements ||
        isFetchingProposalCatalogs ||
        isLoading ||
        isLocked ||
        isSaving;

    return (
        <Dialog
            onClose={() => closeModal()}
            open={isOpenModal}
            title={t('Setting')}
        >
            <CardForm
                canModifyCurrencyType={canModifyCurrencyType}
                canModifyExchangeType={canModifyExchangeType}
                canModifyPlans={canModifyPlans}
                canModifyRoiTaxIncentive={canModifyRoiTaxIncentive}
                canModifyStockExchange={canModifyStockExchange}
                currencies={currencies}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleClickCancel={closeModal}
                isLocked={isLockedForm}
                isOpenModal={isOpenModal}
                isSaving={isSaving}
                maximumDiscount={maximumDiscount}
                nextRateData={nextRateData}
                plans={plans}
                priceByWattMin={priceByWattMin}
                proposalId={proposalId}
                subtotalMin={subtotalMin}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    currencies: proposalGeneratorQuoteSelectors.getCurrenciesData,
    isFetchingOfferComplements: selectors.getIsFetchingOfferComplements,
    isFetchingProposalCatalogs:
        proposalGeneratorQuoteSelectors.getIsFetchingProposalCatalogs,
    isOpenModal: selectors.getIsOpenModal,
    isSaving: selectors.getIsSaving,
    nextRateData: proposalGeneratorOneColumnSelectors.getNextRateData,
    offerComplements: selectors.getOfferComplementsData,
    plans: proposalGeneratorQuoteSelectors.getProposalPlanCatalogs,
    productsTotals: proposalGeneratorQuoteSelectors.getProductsTotals,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    fetchOfferComplements: (proposalId) =>
        dispatch(actions.fetchOfferComplements(proposalId)),
    initialize: (values) => dispatch(actions.initialValues(values)),
});

Card.propTypes = {
    closeModal: PropTypes.func,
    currencies: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    fetchOfferComplements: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    isFetchingOfferComplements: PropTypes.bool,
    isFetchingProposalCatalogs: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSaving: PropTypes.bool,
    nextRateData: PropTypes.object,
    offerComplements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    plans: PropTypes.array,
    productsTotals: PropTypes.object,
    proposalId: PropTypes.string,
    systemSize: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_CURRENCY_TYPE_PERMISSION,
        PERMISSION_LIST.PROPOSAL_EXCHANGE_TYPE_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PLAN_PERMISSION,
        PERMISSION_LIST.PROPOSAL_ROI_TAX_INCENTIVE_PERMISSION,
        PERMISSION_LIST.PROPOSAL_STOCK_EXCHANGE_PERMISSION,
    ]),
)(Card);
