import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

import OpexRangesFields from './OpexRangesFields';

const OpexFields = ({
    canModify,
    control,
    currencyIso,
    currencySymbol,
    isLocked,
    setValue,
}) => {
    const { t } = useTranslation();
    const [
        kwhOpexPeriodicity,
        kwOpexPeriodicity,
        opexActive,
        opexCostingType,
        opexPeriodicity,
    ] = useWatch({
        control,
        name: [
            'kwh_opex_periodicity',
            'kw_opex_periodicity',
            'opex_active',
            'opex_costing_type',
            'opex_periodicity',
        ],
    });
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">OPEX</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isLocked}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="opex_active"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked || !opexActive}
                        fullWidth
                        label={t('Costing type')}
                        name="opex_costing_type"
                        options={[
                            {
                                label: t('Fixed amount'),
                                value: 'by_fixed_amount',
                            },
                            {
                                label: t('Per Watt y kWh'),
                                value: 'by_kw_and_kwh',
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            {opexCostingType === 'by_fixed_amount' && (
                <Grid container mt={1}>
                    <Grid item xs={18} md={6}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append={currencyIso}
                            control={control}
                            disabled={isLocked || !opexActive}
                            fullWidth
                            label={t('Quantity')}
                            min="0"
                            name="opex"
                            prepend={currencySymbol}
                        />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled={isLocked || !opexActive}
                            fullWidth
                            label={t('Annual percentage increase')}
                            min={0}
                            name="opex_percentage_increase"
                            step="any"
                        />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <ToggleButtonGroup
                            color="primary"
                            disabled={isLocked || !opexActive}
                            exclusive
                            fullWidth
                            onChange={(e) =>
                                setValue('opex_periodicity', e.target.value)
                            }
                            value={parseInt(opexPeriodicity)}
                            size="small"
                            sx={{ mb: 2 }}
                        >
                            <ToggleButton value={0}>
                                {t('Monthly')}
                            </ToggleButton>
                            <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            )}

            <OpexRangesFields
                canModify={canModify}
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                isLocked={isLocked}
                kwhOpexPeriodicity={kwhOpexPeriodicity}
                kwOpexPeriodicity={kwOpexPeriodicity}
                opexActive={opexActive}
                opexCostingType={opexCostingType}
                setValue={setValue}
            />
        </>
    );
};

OpexFields.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default OpexFields;
