import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const PPAFields = ({ control, currencyIso, currencySymbol, isLocked }) => {
    const { t } = useTranslation();
    const ppaActive = useWatch({ control, name: 'ppa_active' });

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">PPA</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isLocked}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="ppa_active"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append={currencyIso}
                        control={control}
                        disabled={isLocked || !ppaActive}
                        fullWidth
                        label={t('Price')}
                        min={0}
                        name="ppa_price"
                        prepend={currencySymbol}
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !ppaActive}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="ppa_percentage_increase"
                    />
                </Grid>
            </Grid>
        </>
    );
};

PPAFields.propTypes = {
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
};

export default PPAFields;
