import React from 'react';

import { styled } from '@mui/material/styles';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import DiscountTypeGroup from 'common/components/DiscountTypeGroup';
import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { DISCOUNT_TYPES } from 'common/constants';

import { getProducts } from '../helpers';

import DropdownProducts from './DropdownProducts';
import ProductsModal from './ProductsModal';
import SubtotalColumn from './SubtotalColumn';

const StyledBoxContainer = styled(Box)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#282B30' : '#eef4fa'};
    border-radius: 8px;
    overflow: hidden;
`,
);

const ReduxFieldArrayItems = ({
    canModify,
    control,
    currencyIso,
    currencyLocale,
    currencySymbol,
    formValues,
    handleNormalizeFields,
    handleSaveProductsSettings,
    initialValues,
    initializeProductsSettingsModal,
    isLocked,
    name,
    setValue,
}) => {
    const { t } = useTranslation();

    const { fields } = useFieldArray({ control, name });

    const isDisabled = !canModify || isLocked;

    const handleClickSkip = (i, name, taxItem) => {
        if (taxItem.is_percentage) {
            setValue(`${name}.${i}.percentage_value`, 0);
            handleNormalizeFields(
                i,
                0,
                initialValues?.[name]?.[i]?.percentage_value,
                formValues,
                `${name}.${i}.percentage_value`,
            );
        } else {
            setValue(`${name}.${i}.value`, 0);
            handleNormalizeFields(
                i,
                0,
                initialValues?.[name]?.[i]?.percentage_value,
                formValues,
                `${name}.${i}.value`,
            );
        }
    };

    return (
        <>
            {fields.map((item, i) => {
                const taxItem = formValues?.items?.[i] || {};
                return (
                    <StyledBoxContainer
                        id={`proposal_generator_tax_card_item_${i + 1}`}
                        key={item.id}
                        mb={2}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={18}
                                md={14}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Grid container p={2}>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                md: 'none',
                                            },
                                            ml: 'auto',
                                            mt: 1,
                                        }}
                                    >
                                        <DropdownCardMenu
                                            items={[
                                                {
                                                    handleClick: () =>
                                                        handleClickSkip(
                                                            i,
                                                            name,
                                                            taxItem,
                                                        ),
                                                    label: t('Skip'),
                                                },
                                                {
                                                    handleClick: () =>
                                                        initializeProductsSettingsModal(
                                                            taxItem.id,
                                                            getProducts(
                                                                taxItem,
                                                            ),
                                                        ),
                                                    label: t('Edit products'),
                                                    visible:
                                                        taxItem.is_percentage,
                                                },
                                            ]}
                                            visible={canModify}
                                        />
                                    </Box>
                                    <Grid
                                        item
                                        xs={18}
                                        md={9}
                                        sx={{
                                            '&.MuiGrid-item': {
                                                pt: { xs: 0, md: 2 },
                                            },
                                        }}
                                    >
                                        <ReactHookFormInput
                                            control={control}
                                            disabled={true}
                                            label={t('Name')}
                                            name={`${name}.${i}.name`}
                                            type="text"
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={18} md={9}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {taxItem.is_percentage ? (
                                                <ReactHookFormIntlNumberInput
                                                    control={control}
                                                    disabled={isDisabled}
                                                    fullWidth
                                                    label={t('Amount')}
                                                    min={0}
                                                    name={`${name}.${i}.percentage_value`}
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        handleNormalizeFields(
                                                            i,
                                                            value,
                                                            initialValues?.[
                                                                name
                                                            ]?.[i]
                                                                ?.percentage_value,
                                                            formValues,
                                                            `${name}.${i}.percentage_value`,
                                                        )
                                                    }
                                                    step="any"
                                                    variant="standard"
                                                />
                                            ) : (
                                                <ReactHookFormIntlNumberInput
                                                    control={control}
                                                    disabled={isDisabled}
                                                    fullWidth
                                                    label={t('Amount')}
                                                    min={0}
                                                    name={`${name}.${i}.value`}
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        handleNormalizeFields(
                                                            i,
                                                            value,
                                                            initialValues?.[
                                                                name
                                                            ]?.[i]?.value,
                                                            formValues,
                                                            `${name}.${i}.value`,
                                                        )
                                                    }
                                                    step="any"
                                                    variant="standard"
                                                />
                                            )}
                                            <DiscountTypeGroup
                                                currencySymbol={currencySymbol}
                                                disabled={true}
                                                selected={
                                                    taxItem.is_percentage
                                                        ? DISCOUNT_TYPES.PERCENTAGE
                                                        : DISCOUNT_TYPES.AMOUNT
                                                }
                                            />
                                            {taxItem.is_percentage &&
                                                !isNull(taxItem.elements) && (
                                                    <DropdownProducts
                                                        items={getProducts(
                                                            taxItem,
                                                        )}
                                                    />
                                                )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <SubtotalColumn
                                canModify={canModify}
                                currencyIso={currencyIso}
                                currencyLocale={currencyLocale}
                                handleClickSkip={handleClickSkip}
                                index={i}
                                initializeProductsSettingsModal={
                                    initializeProductsSettingsModal
                                }
                                name={name}
                                productItem={taxItem}
                            />
                        </Grid>
                    </StyledBoxContainer>
                );
            })}

            <ProductsModal
                handleSaveProductsSettings={handleSaveProductsSettings}
            />
        </>
    );
};

ReduxFieldArrayItems.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    handleNormalizeFields: PropTypes.func,
    handleSaveProductsSettings: PropTypes.func,
    initialValues: PropTypes.object,
    initializeProductsSettingsModal: PropTypes.func,
    isLocked: PropTypes.bool,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default ReduxFieldArrayItems;
