import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReduxFieldArrayProposalItems from 'common/components/form/bootstrap/ReduxFieldArrayProposalItems';

import * as selectors from '../selectors';

const FormContainer = ({
    canModifyProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFields,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelField,
    handleNormalizePercentageDiscountField,
    handleRemove,
    initialValues,
    isLoading,
    isLocked,
    isProductCosting,
    itemsData,
    offerItems,
}) => {
    const { control, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
    });
    useEffect(() => reset(initialValues), [initialValues]);
    return (
        <ReduxFieldArrayProposalItems
            canModifyProduct={canModifyProduct}
            control={control}
            currencyIso={currencyIso}
            currencyLocale={currencyLocale}
            currencySymbol={currencySymbol}
            customerMode={customerMode}
            formValues={watch()}
            handleClickDelete={handleClickDelete}
            handleNormalizeDiscountField={handleNormalizeDiscountField}
            handleNormalizeField={handleNormalizeField}
            handleNormalizeFields={handleNormalizeFields}
            handleNormalizeMarginField={handleNormalizeMarginField}
            handleNormalizeModel={handleNormalizeModel}
            handleNormalizeModelField={handleNormalizeModelField}
            handleNormalizePercentageDiscountField={
                handleNormalizePercentageDiscountField
            }
            handleRemove={handleRemove}
            initialValues={initialValues}
            isLoading={isLoading}
            isLocked={isLocked}
            isProductCosting={isProductCosting}
            itemsData={itemsData}
            name="items"
            offerItems={offerItems}
            prefixId="structure"
            setValue={setValue}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    canModifyProduct: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleNormalizeField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    handleNormalizeMarginField: PropTypes.func,
    handleNormalizeModel: PropTypes.func,
    handleNormalizeModelField: PropTypes.func,
    handleNormalizeDiscountField: PropTypes.func,
    handleNormalizePercentageDiscountField: PropTypes.func,
    handleRemove: PropTypes.func,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    itemsData: PropTypes.array,
    offerItems: PropTypes.array,
};

export default connect(mapStateToProps)(FormContainer);
