import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { numberFormat } from 'common/utils/helpers';

import { getProducts } from '../helpers';

const StyledColumn = styled(Grid)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#2f3139' : '#eef4fa'};
`,
);

const SubtotalColumn = ({
    canModify,
    currencyIso,
    currencyLocale,
    handleClickSkip,
    index,
    initializeProductsSettingsModal,
    isLoading,
    name,
    productItem,
}) => {
    const { t } = useTranslation();

    return (
        <StyledColumn
            item
            xs={18}
            md={4}
            sx={{
                '&.MuiGrid-item': {
                    pt: { xs: 0, md: 2 },
                },
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: { xs: 'left', md: 'right' },
                        p: { xs: 2, md: 0 },
                        pt: 0,
                        width: '100%',
                    }}
                >
                    <Typography variant="body2">{t('Subtotal')}</Typography>
                    {isLoading ? (
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: { xs: 200, lg: '100%' },
                            }}
                        />
                    ) : (
                        <Typography variant="body2" fontWeight="bold">
                            {numberFormat(productItem.amount, {
                                currency: currencyIso,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ m: 1, display: { xs: 'none', md: 'block' } }}>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () =>
                                    handleClickSkip(index, name, productItem),
                                label: t('Skip'),
                            },
                            {
                                handleClick: () =>
                                    initializeProductsSettingsModal(
                                        productItem?.id,
                                        getProducts(productItem),
                                    ),
                                label: t('Edit products'),
                                visible: productItem.is_percentage,
                            },
                        ]}
                        visible={canModify}
                    />
                </Box>
            </Box>
        </StyledColumn>
    );
};

SubtotalColumn.propTypes = {
    canModify: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    handleClickSkip: PropTypes.func,
    index: PropTypes.number,
    initializeProductsSettingsModal: PropTypes.func,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    productItem: PropTypes.object,
};

export default SubtotalColumn;
