import i18next from 'i18next';

import { updateItem } from 'common/api/v2/offerComplements';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

import closeModal from './closeModal';

export default (proposalId, values) => (dispatch) => {
    const newValues = {
        bag_years: values.bag_years,
        considerations: values.considerations,
        currency: values.currency,
        custom_offer_template: values.custom_offer_template,
        custom_rate: values.custom_rate,
        deprecated: values.deprecated,
        discount_rate: values.discount_rate,
        generation_delay: values.generation_delay,
        id: values.id,
        isr_percentage: values.isr_percentage,
        isr_simulation_type: values.isr_simulation_type,
        isr_simulation:
            values.isr_simulation_type === 1
                ? values.isr_simulation_annual
                : values.isr_simulation_monthly,
        isr: values.isr,
        kw_opex_percentage_increase: values.kw_opex_percentage_increase,
        kw_opex_periodicity: values.kw_opex_periodicity,
        kw_opex_amount: values.kw_opex_amount,
        kwh_opex_percentage_increase: values.kwh_opex_percentage_increase,
        kwh_opex_periodicity: values.kwh_opex_periodicity,
        kwh_opex_amount: values.kwh_opex_amount,
        opex_active: values.opex_active,
        opex_costing_type: values.opex_costing_type,
        opex_percentage: values.opex_percentage_increase,
        opex_periodicity: values.opex_periodicity,
        opex: values.opex,
        ppa_active: values.ppa_active,
        ppa_percentage_increase: values.ppa_percentage_increase,
        ppa_price: values.ppa_price,
        proposal_plan: values.proposal_plan,
        type_change_offer: values.type_change_offer,
        type_change: values.type_change,
    };

    dispatch(proposalGeneratorConfigModalActions[SAVE]());

    updateItem(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorConfigModalActions[SAVE_SUCCESS](
                    response.data.data,
                ),
            );
            dispatch(closeModal());
            showToast({
                type: 'success',
                body: i18next.t('A new configuration has been applied', {
                    name: values.name,
                }),
            });
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorConfigModalActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
