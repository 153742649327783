import toNumber from 'lodash/toNumber';

import { SET_PRODUCTS_TOTALS } from '../actionTypes';
import { getProfitPercentage } from '../helpers';
import { proposalGeneratorQuoteActions } from '../reducer';
import * as selectors from '../selectors';

import initializeDiscountForm from './initializeDiscountForm';

export default (offerDiscount) => (dispatch, getState) => {
    const state = getState();
    const { discount_amount = 0 } = offerDiscount;
    const totalTaxes = selectors.getTotalCostTaxes(state);
    const totalCost = selectors.getTotalCostProducts(state);
    const subtotal = selectors.getProductsSubtotal(state);
    const profit = subtotal - totalCost;
    const profitPercentage = getProfitPercentage(subtotal, profit);
    const subtotal2 = subtotal - discount_amount;
    const total = subtotal2 + totalTaxes;

    const newValues = {
        profit,
        profitPercentage,
        subtotal,
        subtotalWithDiscount: subtotal2,
        total,
        totalCost,
        totalTaxes,
    };
    dispatch(proposalGeneratorQuoteActions[SET_PRODUCTS_TOTALS](newValues));
    dispatch(
        initializeDiscountForm({
            ...offerDiscount,
            subtotalWithDiscount: toNumber(subtotal2).toFixed(2),
        }),
    );
};
