import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog } from 'sunwise-ui';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import FormContainer from './FormContainer';

const FiltersModal = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation();

    const handleClose = () => setIsOpen(false);
    return (
        <UiDialog
            onClose={handleClose}
            open={isOpen}
            size="xl"
            title={t('Filter', { count: 2 })}
        >
            <FormContainer handleClose={handleClose} />
        </UiDialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsOpenFiltersModal,
});

const mapDispatchToProps = (dispatch) => ({
    setIsOpen: (isOpen) => dispatch(actions.setIsOpenFiltersModal(isOpen)),
});

FiltersModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersModal);
