import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const OpexRangesFields = ({
    canModify,
    control,
    currencyIso,
    currencySymbol,
    isLocked,
    kwhOpexPeriodicity,
    kwOpexPeriodicity,
    opexActive,
    opexCostingType,
    setValue,
}) => {
    const { t } = useTranslation();

    if (opexCostingType === 'by_fixed_amount') return null;

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Cost per kW')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={isLocked || !opexActive || !canModify}
                        fullWidth
                        label={t('Amount')}
                        name="kw_opex_amount"
                        prepend={currencySymbol}
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !opexActive || !canModify}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="kw_opex_percentage_increase"
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={isLocked || !opexActive || !canModify}
                        exclusive
                        fullWidth
                        onChange={(e) =>
                            setValue(
                                'kw_opex_periodicity',
                                parseInt(e.target.value),
                            )
                        }
                        value={parseInt(kwOpexPeriodicity)}
                        size="small"
                        sx={{ mb: 2 }}
                    >
                        <ToggleButton value={0}>{t('Monthly')}</ToggleButton>
                        <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Cost per kWh')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={isLocked || !opexActive || !canModify}
                        fullWidth
                        label={t('Quantity')}
                        name="kwh_opex_amount"
                        prepend={currencySymbol}
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !opexActive || !canModify}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="kwh_opex_percentage_increase"
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={isLocked || !opexActive || !canModify}
                        exclusive
                        fullWidth
                        onChange={(e) =>
                            setValue(
                                'kwh_opex_periodicity',
                                parseInt(e.target.value),
                            )
                        }
                        value={parseInt(kwhOpexPeriodicity)}
                        size="small"
                        sx={{ mb: 2 }}
                    >
                        <ToggleButton value={0}>{t('Monthly')}</ToggleButton>
                        <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </>
    );
};

OpexRangesFields.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
    kwhOpexPeriodicity: PropTypes.number,
    kwOpexPeriodicity: PropTypes.number,
    opexActive: PropTypes.bool,
    opexCostingType: PropTypes.string,
    setValue: PropTypes.func,
};

export default OpexRangesFields;
