import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, ListItemText, Menu, MenuItem } from 'sunwise-ui';

const BulkEditionMenu = ({
    buttonSize,
    items,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'center' },
                gap: 1,
            }}
        >
            <>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    variant="outlined"
                    size={buttonSize}
                >
                    {`${t('Selected')} (${selectionModel.length})`}
                </Button>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {items.map(({ handleClick, label, visible }, index) =>
                        visible ? (
                            <MenuItem
                                key={`bulk-option-${index}`}
                                onClick={() => {
                                    handleClick();
                                    handleClose();
                                }}
                            >
                                <ListItemText primary={label} />
                            </MenuItem>
                        ) : null,
                    )}
                </Menu>
            </>
            <Button
                color="secondary"
                onClick={() => setSelectionModel([])}
                variant="outlined"
                size={buttonSize}
            >
                {t('Cancel')}
            </Button>
        </Box>
    );
};

BulkEditionMenu.defaultProps = {
    buttonSize: 'medium',
    handleClickUpdateMargin: () => {},
    isOpenModalMargin: false,
    show: true,
    showMarginOption: true,
};

BulkEditionMenu.propTypes = {
    buttonSize: PropTypes.string,
    items: PropTypes.array,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkEditionMenu;
