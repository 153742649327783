import React, { useEffect, useState } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Tab, TabPanel, Tabs, Typography } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as multiCurrencySelectors from '../../multiCurrency/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import ProductsTab from './ProductsTab';
import TabConditions from './TabConditions';
import TabCostRanges from './TabCostRanges';
import TabGeneral from './TabGeneral';
import TabSystem from './TabSystem';
import WarningEdition from './WarningEdition';

const FormContainer = ({
    canModify,
    canModifyEnergyBackup,
    currencies,
    errors,
    filterData,
    handleClickSave,
    initialize,
    initialValues,
    isFetchingPlan,
    isInitializing,
    isNewRecord,
    isSaving,
    planData,
    templates,
}) => {
    const { t } = useTranslation();
    const {
        clearErrors,
        control,
        getValues,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors: formErrors },
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [selectedTab, setSelectedTab] = useState('general');
    const [branchOffice, disabledForm, id] = watch([
        'branch_office',
        'disabled',
        'id',
    ]);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (branchOffice) initialize(branchOffice);
    }, [branchOffice]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const handleOnSave = (values) => handleClickSave(filterData, values);

    const isFetching = isFetchingPlan || isInitializing;
    const isDisabled = !canModify || disabledForm || isFetching;

    return (
        <>
            {disabledForm && (
                <WarningEdition
                    handleClickEdit={() => setValue('disabled', false)}
                    show={canModify}
                />
            )}
            <form>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleChange}>
                        <Tab
                            id="tab-general"
                            key="tab-general"
                            label={
                                <Box alignItems="center" display="flex" gap={1}>
                                    {formErrors.name ||
                                    formErrors.branch_office ||
                                    formErrors.installation_type ||
                                    formErrors.saving_percentage ||
                                    formErrors.bag_years ||
                                    formErrors.generation_delay ||
                                    formErrors.costing_type ? (
                                        <WarningIcon
                                            fontSize="small"
                                            sx={{ color: 'error.main' }}
                                        />
                                    ) : null}
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('General')}
                                    </Typography>
                                </Box>
                            }
                            value="general"
                        />
                        {watch('costing_type') === 'by_watt' && (
                            <Tab
                                id="tab-cost-ranges"
                                key="tab-cost-ranges"
                                label={
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        gap={1}
                                    >
                                        {formErrors.cost_ranges_watt ||
                                        formErrors.cost_ranges_kwh ? (
                                            <WarningIcon
                                                fontSize="small"
                                                sx={{ color: 'error.main' }}
                                            />
                                        ) : null}
                                        <Typography
                                            fontWeight="bold"
                                            variant="body2"
                                        >
                                            {t('Cost ranges')}
                                        </Typography>
                                    </Box>
                                }
                                value="cost-ranges"
                            />
                        )}
                        <Tab
                            id="tab-products"
                            key="tab-products"
                            label={
                                <Box alignItems="center" display="flex" gap={1}>
                                    {formErrors.panel ||
                                    formErrors.inverter_brand ? (
                                        <WarningIcon
                                            fontSize="small"
                                            sx={{ color: 'error.main' }}
                                        />
                                    ) : null}
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Product', { count: 2 })}
                                    </Typography>
                                </Box>
                            }
                            value="products"
                        />
                        <Tab
                            id="tab-system"
                            key="tab-system"
                            label={
                                <Box alignItems="center" display="flex" gap={1}>
                                    {formErrors.desired_power_factor ||
                                    formErrors.guaranteed_gen ||
                                    formErrors.ppa_price ||
                                    formErrors.ppa_percentage_increase ||
                                    formErrors.isr_simulation_type ||
                                    formErrors.isr_percentage ||
                                    formErrors.isr_simulation_annual ||
                                    formErrors.isr_simulation_monthly ||
                                    formErrors.opex_costing_type ||
                                    formErrors.opex ||
                                    formErrors.opex_percentage_increase ||
                                    formErrors.opex_cost_ranges_kw ||
                                    formErrors.opex_cost_ranges_kwh ? (
                                        <WarningIcon
                                            fontSize="small"
                                            sx={{ color: 'error.main' }}
                                        />
                                    ) : null}
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('System')}
                                    </Typography>
                                </Box>
                            }
                            value="system"
                        />
                        <Tab
                            id="tab-conditions"
                            key="tab-conditions"
                            label={t('Terms and considerations')}
                            value="conditions"
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-general"
                    selectedTab={selectedTab}
                    value="general"
                >
                    <TabGeneral
                        branchOffice={branchOffice}
                        control={control}
                        id={id}
                        isDisabled={isDisabled}
                        isNewRecord={isNewRecord}
                        planData={planData}
                        templates={templates}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-products"
                    selectedTab={selectedTab}
                    value="products"
                >
                    <ProductsTab
                        battery={watch('battery')}
                        canModifyEnergyBackup={canModifyEnergyBackup}
                        control={control}
                        getValues={getValues}
                        isDisabled={isDisabled || !branchOffice}
                        isNewRecord={isNewRecord}
                        planData={planData}
                        setValue={setValue}
                    />
                </TabPanel>

                {watch('costing_type') === 'by_watt' && (
                    <TabPanel
                        key="tab-cost-ranges"
                        selectedTab={selectedTab}
                        value="cost-ranges"
                    >
                        <TabCostRanges
                            currencies={currencies}
                            control={control}
                            getValues={getValues}
                            isDisabled={isDisabled}
                            setValue={setValue}
                        />
                    </TabPanel>
                )}

                <TabPanel
                    key="tab-system"
                    selectedTab={selectedTab}
                    value="system"
                >
                    <TabSystem
                        clearErrors={clearErrors}
                        control={control}
                        getValues={getValues}
                        isDisabled={isDisabled}
                        setValue={setValue}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-conditions"
                    selectedTab={selectedTab}
                    value="conditions"
                >
                    <TabConditions control={control} isDisabled={isDisabled} />
                </TabPanel>

                <ShowErrors errors={errors} />

                <Grid container mt={1}>
                    <Grid item textAlign="right" xs={18}>
                        <Button
                            disabled={isSaving}
                            onClick={handleSubmit(handleOnSave)}
                            sx={{
                                '&.MuiButton-root': {
                                    mb: { xs: 'auto', md: 0 },
                                },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="button"
                            variant="outlined"
                            visible={canModify && !disabledForm}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    errors: selectors.getSaveItemErrors,
    initialValues: selectors.getInitialValues,
    isFetchingPlan: selectors.getIsFetchingPlan,
    isInitializing: selectors.getIsInitializing,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    planData: selectors.getFetchPlanData,
    templates: selectors.getFetchTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickDelete: () => dispatch(actions.prepareDelete()),
    handleClickSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
    initialize: (branchOffice) =>
        dispatch(actions.initialFetching(branchOffice)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    canModifyEnergyBackup: PropTypes.bool,
    currencies: PropTypes.array,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickSave: PropTypes.func,
    initialize: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingPlan: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    planData: PropTypes.object,
    templates: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
