import API from '../..';

const config = { headers: { 'Content-Type': 'application/json' } };
const ENTITY = 'consumption-and-generation-offer';

export const getItems = (offerId) => API.get(`/api/v2/${ENTITY}/${offerId}`);
export const updateAdvancedConsumptionSettings = (offerId, data) =>
    API.put(`/api/v2/consumption-configuration-offer/${offerId}`, data);
export const updateAdvancedGenerationSettings = (data, offerId) =>
    API.put(
        `/api/v2/generation-advanced-settings-offer/${offerId}`,
        data,
        config,
    );
export const updateProposalSettings = (data, offerId) =>
    API.put(`/api/v2/proposal-configuration/${offerId}`, data, config);
