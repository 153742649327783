import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

const PowerFactorFields = ({ control, isLocked }) => {
    const { t } = useTranslation();
    const apply_desired_power_factor = useWatch({
        control,
        name: 'apply_desired_power_factor',
    });

    return (
        <Box alignItems="center" display="flex" gap={1} mb={2}>
            <ReactHookFormCheck
                control={control}
                disabled={isLocked}
                label={''}
                name="apply_desired_power_factor"
                sxFormControl={{
                    mb: '0px !important',
                    '& .MuiFormControlLabel-root': { margin: 0 },
                }}
            />

            <ReactHookFormIntlNumberInput
                control={control}
                disabled={isLocked || !apply_desired_power_factor}
                fullWidth
                label={t('Target power factor')}
                name="desired_power_factor"
            />
        </Box>
    );
};

PowerFactorFields.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default PowerFactorFields;
