import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import {
    IconAdditional,
    IconAccessory,
    IconEnergyBackup,
    IconInverter,
    IconPanel,
    IconStructure,
    IconWorkforce,
} from 'common/components/icons';

import { getOptionsToSelect, getOptionsToSelectForRHFArray } from '../helpers';
import * as selectors from '../selectors';

import ProductsContainer from './ProductsContainer';
import ReactHookFormArray from './ReactHookFormArray';

const ProductsTab = ({
    accessories,
    additionals,
    batteries,
    battery,
    canModifyEnergyBackup,
    control,
    getValues,
    inverters,
    isDisabled,
    isNewRecord,
    panels,
    planData,
    setValue,
    structures,
    workforce,
}) => {
    const { t } = useTranslation();

    const isDisabledBatteryField = !canModifyEnergyBackup || isDisabled;

    return (
        <>
            <ProductsContainer icon={<IconPanel />} title={t('Panel model')}>
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    name="panel"
                    options={getOptionsToSelect({
                        data: panels,
                        isNewRecord,
                        label: t('Select panel'),
                        planData,
                        prefix: 'panel_branch_office',
                    })}
                />
            </ProductsContainer>

            <ProductsContainer
                icon={<IconInverter color="#41c4d9" />}
                title={t('Inverter brand')}
            >
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    name="inverter_brand"
                    options={getOptionsToSelect({
                        data: inverters,
                        isNewRecord,
                        label: t('Select inverter'),
                        planData,
                        prefix: 'inverter_brand',
                    })}
                />
            </ProductsContainer>

            <ProductsContainer
                icon={<IconEnergyBackup color="#8bc34a" />}
                title={t('Storage model')}
            >
                <Box display="flex" gap={1}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabledBatteryField}
                        name="battery"
                        options={getOptionsToSelect({
                            data: batteries,
                            isNewRecord,
                            label: t('Select an storage'),
                            planData,
                            prefix: 'battery_branch_office',
                        })}
                    />
                    <Box hidden={isEmpty(battery)}>
                        <IconButton
                            disabled={isDisabledBatteryField}
                            onClick={() => setValue('battery', '')}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            </ProductsContainer>

            <ProductsContainer
                icon={<IconAccessory color="#f9c109" />}
                title={t('Accessory', { count: 2 })}
            >
                <ReactHookFormArray
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    groupName="accessories"
                    itemsToSelect={getOptionsToSelectForRHFArray({
                        data: accessories,
                        isNewRecord,
                        label: t('Select accessories'),
                        planData,
                        prefix: 'accessories_branch_office',
                    })}
                />
            </ProductsContainer>

            <ProductsContainer
                icon={<IconStructure color="#f67044" />}
                title={t('Structure', { count: 2 })}
            >
                <ReactHookFormArray
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    groupName="structures"
                    itemsToSelect={getOptionsToSelectForRHFArray({
                        data: structures,
                        isNewRecord,
                        label: t('Select structure'),
                        planData,
                        prefix: 'structures',
                    })}
                />
            </ProductsContainer>

            <ProductsContainer
                icon={<IconWorkforce color="#e91e63" />}
                title={t('Workforce and electrical equipment')}
            >
                <ReactHookFormArray
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    groupName="workforce_and_electric_materials"
                    itemsToSelect={getOptionsToSelectForRHFArray({
                        data: workforce,
                        isNewRecord,
                        label: t('Select workforce and electrical material'),
                        planData,
                        prefix: 'workforce_and_electric_materials_branch_office',
                    })}
                />
            </ProductsContainer>

            <ProductsContainer
                icon={<IconAdditional color="#7046bc" />}
                title={t('Additional', { count: 2 })}
            >
                <ReactHookFormArray
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    groupName="additionals"
                    itemsToSelect={getOptionsToSelectForRHFArray({
                        data: additionals,
                        isNewRecord,
                        label: t('Select additional products'),
                        planData,
                        prefix: 'additionals_branch_office',
                    })}
                />
            </ProductsContainer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    accessories: selectors.getFetchAccessoriesData,
    additionals: selectors.getFetchAdditionalsData,
    batteries: selectors.getFetchBatteriesData,
    inverters: selectors.getFetchInvertersData,
    panels: selectors.getFetchPanelsData,
    structures: selectors.getFetchStructuresData,
    workforce: selectors.getFetchWorkforceData,
});

ProductsTab.propTypes = {
    accessories: PropTypes.array,
    additionals: PropTypes.array,
    batteries: PropTypes.array,
    battery: PropTypes.string,
    canModifyEnergyBackup: PropTypes.bool,
    control: PropTypes.object,
    getValues: PropTypes.func,
    inverters: PropTypes.array,
    isDisabled: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    panels: PropTypes.array,
    planData: PropTypes.object,
    setValue: PropTypes.func,
    structures: PropTypes.array,
    workforce: PropTypes.array,
};

export default connect(mapStateToProps, null)(ProductsTab);
