import React from 'react';

import { Box, Grid } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const GuaranteedGenerationFields = ({ control, isLocked = false }) => {
    const { t } = useTranslation();
    const guaranteedGenerationActive = useWatch({
        control,
        name: 'guaranteed_gen_active',
    });

    return (
        <Box px={2}>
            <TitleWithDetail variant="body2">
                {t('Guaranteed generation')}
            </TitleWithDetail>
            <Grid container>
                <Grid item xs={18} md={4} lg={3}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isLocked}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="guaranteed_gen_active"
                    />
                </Grid>
                <Grid item xs={18} md={4} lg={3}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !guaranteedGenerationActive}
                        fullWidth
                        label={t('Percentage')}
                        max={100}
                        min={0}
                        name="guaranteed_gen"
                        placeholder="90"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

GuaranteedGenerationFields.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default GuaranteedGenerationFields;
