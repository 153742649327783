import fetchCurrencies from '../../multiCurrency/actions/filterItems';
import {
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

import fetchAccessories from './fetchAccessories';
import fetchAdditionals from './fetchAdditionals';
import fetchBatteries from './fetchBatteries';
import fetchInverters from './fetchInverters';
import fetchPanels from './fetchPanels';
import fetchStructures from './fetchStructures';
import fetchTemplates from './fetchTemplates';
import fetchWorkforce from './fetchWorkforce';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[INITIALIZE]());
    Promise.all([
        dispatch(fetchAccessories(branchOffice)),
        dispatch(fetchAdditionals(branchOffice)),
        dispatch(fetchBatteries(branchOffice)),
        dispatch(fetchCurrencies()),
        dispatch(fetchInverters(branchOffice)),
        dispatch(fetchPanels(branchOffice)),
        dispatch(fetchStructures(branchOffice)),
        dispatch(fetchTemplates(branchOffice)),
        dispatch(fetchWorkforce(branchOffice)),
    ])
        .then(() => dispatch(planConfigurationActions[INITIALIZE_SUCCESS]()))
        .catch(() => dispatch(planConfigurationActions[INITIALIZE_FAILURE]()));
};
