import { NAME } from './constants';

export const FETCH_OFFER_PRODUCTS = `${NAME}/FETCH_OFFER_PRODUCTS`;
export const FETCH_OFFER_PRODUCTS_FAILURE = `${NAME}/FETCH_OFFER_PRODUCTS_FAILURE`;
export const FETCH_OFFER_PRODUCTS_SUCCESS = `${NAME}/FETCH_OFFER_PRODUCTS_SUCCESS`;

export const FETCH_PROPOSAL_CATALOGS = `${NAME}/FETCH_PROPOSAL_CATALOGS`;
export const FETCH_PROPOSAL_CATALOGS_FAILURE = `${NAME}/FETCH_PROPOSAL_CATALOGS_FAILURE`;
export const FETCH_PROPOSAL_CATALOGS_SUCCESS = `${NAME}/FETCH_PROPOSAL_CATALOGS_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const INITIALIZE_COSTING_FORM = `${NAME}/INITIALIZE_COSTING_FORM`;
export const INITIALIZE_DISCOUNT_FORM = `${NAME}/INITIALIZE_DISCOUNT_FORM`;
export const INITIALIZE_UPDATE_PRODUCTS_FORM = `${NAME}/INITIALIZE_UPDATE_PRODUCTS_FORM`;

export const RESET_DATA = `${NAME}/RESET_DATA`;
export const RESET_COSTING_FORM = `${NAME}/RESET_COSTING_FORM`;
export const RESET_UPDATE_PRODUCTS_FORM = `${NAME}/RESET_UPDATE_PRODUCTS_FORM`;

export const SAVE_BULK_UPDATE_PRODUCTS = `${NAME}/SAVE_BULK_UPDATE_PRODUCTS`;
export const SAVE_BULK_UPDATE_PRODUCTS_FAILURE = `${NAME}/SAVE_BULK_UPDATE_PRODUCTS_FAILURE`;
export const SAVE_BULK_UPDATE_PRODUCTS_SUCCESS = `${NAME}/SAVE_BULK_UPDATE_PRODUCTS_SUCCESS`;

export const SAVE_COSTING = `${NAME}/SAVE_COSTING`;
export const SAVE_COSTING_FAILURE = `${NAME}/SAVE_COSTING_FAILURE`;
export const SAVE_COSTING_SUCCESS = `${NAME}/SAVE_COSTING_SUCCESS`;

export const SAVE_DISCOUNT = `${NAME}/SAVE_DISCOUNT`;
export const SAVE_DISCOUNT_FAILURE = `${NAME}/SAVE_DISCOUNT_FAILURE`;
export const SAVE_DISCOUNT_SUCCESS = `${NAME}/SAVE_DISCOUNT_SUCCESS`;

export const SET_BULK_ACTION = `${NAME}/SET_BULK_ACTION`;
export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
export const SET_PRODUCTS_TOTALS = `${NAME}/SET_PRODUCTS_TOTALS`;
