import React, { useEffect, useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, ListItemText, Menu, MenuItem } from 'sunwise-ui';

const BulkEditionMenu = ({ callback, items, totalItems }) => {
    const { t } = useTranslation();
    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonWidth, setButtonWidth] = useState(0);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (buttonRef.current) {
            setButtonWidth(buttonRef.current.offsetWidth);
        }
    }, [buttonRef.current]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'end' },
                gap: 1,
                my: 1,
            }}
        >
            <>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    ref={buttonRef}
                    size="small"
                    variant="outlined"
                >
                    {`${t('Selected')} (${totalItems})`}
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            minWidth: buttonWidth,
                        },
                    }}
                >
                    {items.map(({ handleClick, label, visible }, index) =>
                        visible ? (
                            <MenuItem
                                key={`bulk-option-${index}`}
                                onClick={() => {
                                    handleClick();
                                    handleClose();
                                }}
                            >
                                <ListItemText primary={label} />
                            </MenuItem>
                        ) : null,
                    )}
                </Menu>
            </>
            <Button
                color="secondary"
                onClick={callback}
                size="small"
                variant="outlined"
            >
                {t('Cancel')}
            </Button>
        </Box>
    );
};

BulkEditionMenu.defaultProps = {
    handleClickUpdateMargin: () => {},
    isOpenModalMargin: false,
    show: true,
    showMarginOption: true,
};

BulkEditionMenu.propTypes = {
    callback: PropTypes.func,
    items: PropTypes.array,
    totalItems: PropTypes.number,
};

export default BulkEditionMenu;
