import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_FINANCIAL_PRODUCT,
    DELETE_FINANCIAL_PRODUCT_FAILURE,
    DELETE_FINANCIAL_PRODUCT_SUCCESS,
    FETCH_ALLIANCE_PRODUCTS,
    FETCH_ALLIANCE_PRODUCTS_FAILURE,
    FETCH_ALLIANCE_PRODUCTS_SUCCESS,
    FETCH_ALLIANCE_PRODUCT_WARNINGS,
    FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE,
    FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS,
    FETCH_FINANCIAL_PRODUCTS_PLAN,
    FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE,
    FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS,
    FETCH_FINANCIER_INSTALLERS,
    FETCH_FINANCIER_INSTALLERS_FAILURE,
    FETCH_FINANCIER_INSTALLERS_SUCCESS,
    FETCH_FINANCIERS,
    FETCH_FINANCIERS_FAILURE,
    FETCH_FINANCIERS_SUCCESS,
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_FAILURE,
    FETCH_REQUIREMENTS_SUCCESS,
    INITIAL_VALUES_CI_BANCO,
    INITIAL_VALUES,
    RESET_FORM_CI_BANCO,
    RESET_FORM,
    SAVE_FINANCIERS_INSTALLER,
    SAVE_FINANCIERS_INSTALLER_FAILURE,
    SAVE_FINANCIERS_INSTALLER_SUCCESS,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_OPEN_ADD_ALLIANCE_MODAL,
    SET_IS_OPEN_ALLIANCE_MODAL,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    allianceProducts: {
        data: [],
        errors: [],
        isFetching: false,
    },
    allianceProductWarnings: {
        data: [],
        errors: [],
        isFetching: false,
    },
    deleteFinancialProduct: {
        data: [],
        errors: [],
        isDeleting: false,
    },
    financierInstallers: {
        data: [],
        errors: [],
        isFetching: false,
    },
    financiers: {
        data: [],
        errors: [],
        isFetching: true,
    },
    financialProductsPlan: {
        data: [],
        errors: [],
        isFetching: false,
    },
    initialValuesCiBanco: {
        amount: 0,
        commercial_offer: null,
        commission_credit: 0,
        commission_credit_amount: 0,
        extra_commission: 0,
        financier_installer: null,
        financier_product_insurance: null,
        financier_product: null,
        hitch_percentage: 0,
        hitch: 0,
        insurance: 0,
        is_commission_percentage: false,
        is_percentage: false,
        is_serfimex: false,
        rate: 0,
        residual: 0,
        step: 1,
        term: '',
        termsToSelect: [],
        total_with_dealer_fee: 0,
        types_business_company: '',
        parameters: {},
    },
    initialValues: {
        amount: 0,
        commission_credit: 0,
        commission_credit_amount: 0,
        file_percentage: null,
        hitch: 0,
        hitch_percentage: 0,
        insurance: 0,
        is_commission_percentage: false,
        is_percentage: false,
        name: '',
        product_financial_plan: null,
        rate: 0,
        residual: 0,
        term: '',
        termsToSelect: [],
        total_with_dealer_fee: 0,
    },
    isOpenAddAllianceModal: false,
    isOpenAllianceModal: false,
    isOpenModal: false,
    requirements: {
        data: null,
        errors: [],
        isFetching: true,
    },
    saveFinanciersInstaller: {
        data: [],
        errors: [],
        isFetching: false,
    },
    save: {
        data: [],
        errors: [],
        isSaving: false,
    },
    termInitialValues: {
        companyRegistered: false,
    },
};

const financingAndInsuranceSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_FINANCIAL_PRODUCT]: (state) => {
            state.deleteFinancialProduct = {
                ...state.deleteFinancialProduct,
                data: {},
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_FINANCIAL_PRODUCT_FAILURE]: (state, action) => {
            state.deleteFinancialProduct = {
                ...state.deleteFinancialProduct,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_FINANCIAL_PRODUCT_SUCCESS]: (state) => {
            state.deleteFinancialProduct = {
                ...state.deleteFinancialProduct,
                data: {},
                isDeleting: false,
            };
        },
        [FETCH_ALLIANCE_PRODUCTS]: (state) => {
            state.allianceProducts = {
                ...state.allianceProducts,
                data: [],
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ALLIANCE_PRODUCTS_FAILURE]: (state, action) => {
            state.allianceProducts = {
                ...state.allianceProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALLIANCE_PRODUCTS_SUCCESS]: (state, action) => {
            state.allianceProducts = {
                ...state.allianceProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALLIANCE_PRODUCT_WARNINGS]: (state) => {
            state.allianceProductWarnings = {
                ...state.allianceProductWarnings,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE]: (state, action) => {
            state.allianceProductWarnings = {
                ...state.allianceProductWarnings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS]: (state, action) => {
            state.allianceProductWarnings = {
                ...state.allianceProductWarnings,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_PRODUCTS_PLAN]: (state) => {
            state.financialProductsPlan = {
                ...state.financialProductsPlan,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE]: (state, action) => {
            state.financialProductsPlan = {
                ...state.financialProductsPlan,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS]: (state, action) => {
            state.financialProductsPlan = {
                ...state.financialProductsPlan,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLERS]: (state) => {
            state.financierInstallers = {
                ...state.financierInstallers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIER_INSTALLERS_FAILURE]: (state, action) => {
            state.financierInstallers = {
                ...state.financierInstallers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLERS_SUCCESS]: (state, action) => {
            state.financierInstallers = {
                ...state.financierInstallers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS]: (state) => {
            state.financiers = {
                ...state.financiers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCIERS_FAILURE]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIERS_SUCCESS]: (state, action) => {
            state.financiers = {
                ...state.financiers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS]: (state) => {
            state.requirements = {
                ...state.requirements,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_REQUIREMENTS_FAILURE]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIREMENTS_SUCCESS]: (state, action) => {
            state.requirements = {
                ...state.requirements,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIAL_VALUES_CI_BANCO]: (state, action) => {
            state.initialValuesCiBanco = {
                ...state.initialValuesCiBanco,
                ...action.payload,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_FORM_CI_BANCO]: (state) => {
            state.initialValuesCiBanco = INITIAL_STATE.initialValuesCiBanco;
        },
        [SAVE_FINANCIERS_INSTALLER]: (state) => {
            state.saveFinanciersInstaller = {
                ...state.saveFinanciersInstaller,
                errors: [],
                isFetching: true,
            };
        },
        [SAVE_FINANCIERS_INSTALLER_FAILURE]: (state, action) => {
            state.saveFinanciersInstaller = {
                ...state.saveFinanciersInstaller,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_FINANCIERS_INSTALLER_SUCCESS]: (state, action) => {
            state.saveFinanciersInstaller = {
                ...state.financiersInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_OPEN_ADD_ALLIANCE_MODAL]: (state, action) => {
            state.isOpenAddAllianceModal = action.payload;
        },
        [SET_IS_OPEN_ALLIANCE_MODAL]: (state, action) => {
            state.isOpenAllianceModal = action.payload;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const financingAndInsuranceActions = financingAndInsuranceSlice.actions;

export default financingAndInsuranceSlice.reducer;
