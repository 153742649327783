import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const SimulationFields = ({ canModifyStockExchange, control, isDisabled }) => {
    const { t } = useTranslation();
    const options = new Array(12).fill(0).map((_, index) => ({
        label: `${index} ${i18next.t('Month', {
            count: index,
        })}`,
        value: index.toString(),
    }));

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Simulation')}
                </TitleWithDetail>
            </Box>
            <Grid container>
                <Grid item sm={9} md={6} xs={18}>
                    <ReactHookFormIntlNumberInput
                        append={'%'}
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Percentage of energy savings')}
                        max={100}
                        min={0}
                        name="saving_percentage"
                    />
                </Grid>
                <Grid item sm={9} md={6} xs={18}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        append={t('Year', { count: 2 })}
                        control={control}
                        disabled={isDisabled || !canModifyStockExchange}
                        fullWidth
                        label={t('Simulation years')}
                        max={50}
                        min={0}
                        name="bag_years"
                    />
                </Grid>
                <Grid item sm={9} md={6} xs={18}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabled}
                        name="generation_delay"
                        label={t('Time to commissioning')}
                        options={options}
                    />
                </Grid>
            </Grid>
        </>
    );
};

SimulationFields.propTypes = {
    canModifyStockExchange: PropTypes.bool,
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default SimulationFields;
