import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import proposalGeneratorTaxesTable from '../../proposalGeneratorTaxesTable';

import ConceptValue from './ConceptValue';
import DiscountValue from './DiscountValue';
import SubtotalValue from './SubtotalValue';

const Totals = ({
    canModify,
    canModifyCost,
    canModifyMargin,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    isFetching,
    isLocked,
    offerDiscount,
    profit,
    profitPercentage,
    proposalId,
    subtotal,
    subtotalWithDiscount,
    total,
    totalCost,
    totalTaxes,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [discountEditable, setDiscountEditable] = useState(false);
    const [subtotal2Editable, setSubtotal2Editable] = useState(false);

    if (!canModify) return null;

    let newDiscount = offerDiscount?.discount_amount;
    if (offerDiscount?.discount_amount === 0)
        newDiscount = offerDiscount?.discount_amount * -1;

    return (
        <>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small" sx={{ minWidth: 650 }}>
                    <TableBody>
                        <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Cost')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    isHidden={customerMode || !canModifyCost}
                                    value={totalCost}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                backgroundColor: 'action.hover',
                                '.MuiTableCell-body': { py: 1 },
                            }}
                        >
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Subtotal')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    value={subtotal}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Discount')} (
                                    {offerDiscount?.discount_percentage}%)
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <DiscountValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    currencySymbol={currencySymbol}
                                    disabledForm={
                                        isFetching ||
                                        isLocked ||
                                        subtotal2Editable
                                    }
                                    discountEditable={discountEditable}
                                    isFetching={isFetching}
                                    isHidden={customerMode}
                                    proposalId={proposalId}
                                    setDiscountEditable={setDiscountEditable}
                                    subtotal={subtotal}
                                    value={newDiscount}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                backgroundColor: 'action.hover',
                                '.MuiTableCell-body': { py: 1 },
                            }}
                        >
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {`${t('Subtotal')} (2)`}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <SubtotalValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    currencySymbol={currencySymbol}
                                    disabledForm={
                                        isFetching ||
                                        isLocked ||
                                        discountEditable
                                    }
                                    isFetching={isFetching}
                                    isHidden={customerMode}
                                    proposalId={proposalId}
                                    setSubtotal2Editable={setSubtotal2Editable}
                                    subtotal={subtotal}
                                    subtotal2Editable={subtotal2Editable}
                                    value={subtotalWithDiscount}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Box alignItems="center" display="flex" gap={2}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Tax', { count: 2 })}
                                    </Typography>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {open ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )}
                                    </IconButton>
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    isHidden={customerMode}
                                    value={totalTaxes}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <proposalGeneratorTaxesTable.Container
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        currencySymbol={currencySymbol}
                                        isLoading={isFetching}
                                        isLocked={isFetching || isLocked}
                                        proposalId={proposalId}
                                    />
                                </Collapse>
                            </TableCell>
                        </TableRow>

                        <TableRow
                            sx={{
                                backgroundColor: 'action.hover',
                                '.MuiTableCell-body': { py: 1 },
                            }}
                        >
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Total')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    value={total}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {`${t('Profit')} (${profitPercentage}%)`}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    isHidden={customerMode || !canModifyMargin}
                                    value={profit}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

Totals.propTypes = {
    canModify: PropTypes.bool,
    canModifyCost: PropTypes.bool,
    canModifyMargin: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    isFetching: PropTypes.bool,
    isLocked: PropTypes.bool,
    offerDiscount: PropTypes.object,
    profit: PropTypes.number,
    profitPercentage: PropTypes.number,
    proposalId: PropTypes.string,
    subtotal: PropTypes.number,
    subtotalWithDiscount: PropTypes.number,
    total: PropTypes.number,
    totalCost: PropTypes.number,
    totalTaxes: PropTypes.number,
};

export default withPermissions(PERMISSION_LIST.SEE_FINAL_BREAKDOWN)(Totals);
